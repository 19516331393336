<template>
  <div>
    <a
      v-for="some in somes"
      :key="some.code"
      :href="some.url"
      :title="some.name"
      rel="noopener"
      target="_blank"
    >
      <component :is="some.component" size="1x" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { YoutubeIcon, InstagramIcon, FacebookIcon } from '~/components/icon'

const somes = [{
  code: 'facebook',
  name: 'Facebook',
  url: 'https://www.facebook.com/tarvikecom/',
  component: FacebookIcon
},
{
  code: 'instagram',
  name: 'Instagram',
  url: 'https://www.instagram.com/tarvikecom/',
  component: InstagramIcon
},
{
  code: 'youtube',
  name: 'YouTube',
  url: 'https://www.youtube.com/channel/UCY9AQwtLYv_e412-1y35_ig',
  component: YoutubeIcon
}]
</script>
