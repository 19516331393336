<template>
  <div
    class="tk-review-stars"
    :class="{ animate }"
    :title="title"
    :style="{ '--score-width': width }"
  />
</template>

<script setup lang="ts">
// Define the props
const props = defineProps({
  score: {
    type: Number,
    required: false,
    default: 0,
    validator: (score: number) => score >= 0 && score <= 5
  },
  animate: {
    type: Boolean,
    required: false,
    default: false
  }
})

// Computed properties
const reviewScore = computed(() => parseFloat((props.score).toFixed(1)))
const width = computed(() => Math.ceil((reviewScore.value / 5) * 100) + '%')
const title = computed(() => props.score ? `Arvosana ${reviewScore.value} / 5` : 'Ei arvosteluja.')
</script>

<style lang="scss">
.tk-review-stars {
  @apply text-yellow-300 dark:text-yellow-600 bg-grey-100 overflow-hidden w-min;
  height: 1.25em;
  mask-image: url(~/assets/images/five-stars.svg);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  aspect-ratio: 110/20;
  &:after {
    @apply content-[''] block h-full bg-current;
    width: var(--score-width);
  }
  &.animate:after {
    animation: .5s ease-out forwards fivestars;
  }
}
@keyframes fivestars {
  0% {
    transform: translateX(-100%);
    filter: brightness(1.2);
  }
  100% {
    transform: translateX(0);
    filter: brightness(1);
  }
}
</style>
