import { defineStore } from 'pinia'
import type { CategoryItem, CategoryTreeItem } from '~/types/category'

export const useCategoryStore = defineStore('category', {
  state: () => ({
    categories: [] as CategoryItem[],
    // CategoryTree currently not populated
    categoryTree: null as CategoryTreeItem[] | null,
    fetched: false
  }),

  actions: {
    // Not used in the current implementation
    async fetchCategoryTree(payload: string) {
      try {
        const { data }: { data: CategoryTreeItem[] } = await $fetch(`${payload}/category/categories-v2.json`)
        this.categoryTree = data
      }
      catch (error) {
        // @TODO: Add Sentry logging
        console.error(error)
      }
    },
    async fetchCategories() {
      try {
        // const { data }: { data: CategoryItem[] } = await $fetch(`${payload}/category/category-list-v4.json`)
        // We could also fetch only the main categories (fetchMainCategories) at first and then fetch the rest of the categories when needed
        // Use requestOptions.filter: const filter = 'level: 1 AND isIncludedInMenu: true'
        const { data, error }: { data: any, error: any } = await useAsyncAlgoliaSearch({ key: 'fetch_categories', indexName: useRuntimeConfig().public.ALGOLIA_CATEGORY_INDEX, query: '', requestOptions: { hitsPerPage: 1000, distinct: true, attributesToHighlight: [] } })
        if (error.value) {
          console.error(error.value)
          return false
        }
        else {
          this.categories = data.value?.hits as CategoryItem[]
          this.fetched = true
          return true
        }
      }
      catch (error) {
        // @TODO: Add Sentry loggin
        console.error(error)
        return false
      }
    },
    buildCategoryTree() {
      const categoryMap = new Map()

      // Create a map of categories by their IDs
      this.categories.forEach(category => categoryMap.set(category.id, { ...category, children: [] }))

      const tree: CategoryTreeItem[] = []

      // Loop through the categories and build the tree
      this.categories.forEach((category: CategoryItem) => {
        const current = categoryMap.get(category.id)
        if (!category.parentId) {
          tree.push(current)
        }
        else {
          const parent = categoryMap.get(category.parentId)
          if (parent) {
            parent.children.push(current)
          }
        }
      })

      this.categoryTree = tree
    }
  },

  getters: {
    getCategoryByPath: state => (path: string): CategoryItem | undefined => {
      return state.categories.find((c: CategoryItem) => c.path === path)
    },
    getSubCategoriesByPath: state => (path: string): CategoryItem[] => {
      const category = state.categories.find((c: CategoryItem) => c.path === path)
      return state.categories.filter(cat => category?.children?.includes(cat.id))
    },
    getCategoryById: state => (id: CategoryItem['id']): CategoryItem | undefined => {
      return state.categories.find((c: CategoryItem) => c.id === id)
    },
    getCategoryByName: state => (name: string): CategoryItem | undefined => {
      return state.categories.find((c: CategoryItem) => c.name === name)
    },
    getMainCategories: (state): CategoryItem[] => {
      return state.categories.filter((c: CategoryItem) => c.level === 1 && c.isIncludedInMenu).sort((a: CategoryItem, b: CategoryItem) => (a.sortOrder || 0) - (b.sortOrder || 0))
    },
    getCategoryTree: (state): CategoryTreeItem[] => {
      return state.categoryTree?.slice().sort((a: CategoryTreeItem, b: CategoryTreeItem) => (a.sortOrder || 0) - (b.sortOrder || 0)) || []
    },
    getPopularCategories: (state): CategoryItem[] => {
      return state.categories.filter((c: CategoryItem) => c.popular === true && !c.path?.startsWith('brandit/'))
    },
    getPopularBrands: (state): CategoryItem[] => {
      return state.categories.filter((c: CategoryItem) => c.popular === true && c.path?.startsWith('brandit/'))
    },
    isFetched: state => state.fetched
  }
})
