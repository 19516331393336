export default function useIcon(name: string) {
  return ({
    name,
    props: {
      size: {
        type: String,
        default: '24',
        validator: function validator(s: any) {
          return !Number.isNaN(s) || (s.length >= 2 && !Number.isNaN(s.slice(0, s.length - 1)) && s.slice(-1) === 'x')
        }
      }
    },
    computed: {
      iconSize(): string {
        // @ts-ignore
        return this.size.slice(-1) === 'x' ? `${this.size.slice(0, this.size.length - 1)}em` : `${parseInt(this.size, NaN)}px`
      }
    }
  })
}
