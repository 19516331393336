import type { Customization, CustomizationWithIdentifier, CustomizationWithPrice } from '~/types/customization'
import type { CartItem, ShareCartItem, WishlistItem } from '~/types/item'
import type { AttributeValueObject, ProductAlgolia, UnitObjectValue } from '~/types/product'

/**
 * Example returns:
 * - TUOTESKU-size:(101102)
 * - TUOTESKU-upholstery:(storm_viuhka1_storm_02_valkoinen)
 */
export const calculateCustomizationIdentifier = (sku: string, customization: Customization) => customization
  ? [sku, Object.entries(customization.properties).map(([k, p]) => `${k}:(${Object.values(p?.value || {}).join('')})`
    )].join('-')
  : sku

const calculateCustomSizePrice = (price: number, width: number, length: number): number => {
  const unitPrice = price / (100 * 100)
  // Javascript rounding: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
  return Math.round((unitPrice * width * length + Number.EPSILON) * 100) / 100
}

export const recalculateCustomizationPrice = (finalPrice: number, price: number, customization: CustomizationWithIdentifier): CustomizationWithPrice => {
  if (customization?.properties?.size?.value) {
    return ({
      ...customization,
      price: calculateCustomSizePrice(price, customization.properties.size.value.width, customization.properties.size.value.length),
      finalPrice: calculateCustomSizePrice(finalPrice, customization.properties.size.value.width, customization.properties.size.value.length)
    })
  }
  else {
    return ({
      ...customization,
      price,
      finalPrice
    })
  }
}

export const getProductTitle = (defaultTitle: string, customization?: Customization | CustomizationWithIdentifier) => {
  const customPropertyLabels: string[] = [defaultTitle]
  if (customization) {
    Object.values(customization.properties).forEach((property) => {
      if (property?.label) {
        customPropertyLabels.push(property.label)
      }
    })
  }

  return customPropertyLabels.filter(Boolean).join(' ')
}

export const getCustomSizeLabel = (width: number, length: number): string => {
  return `(Koko: leveys: ${width} cm, pituus: ${length} cm)`
}

export const getCustomUpholsteryLabel = (code: string, values: UnitObjectValue<AttributeValueObject[]>): string => {
  return `(Verhoilu: ${values.value.find(upholstery => upholstery.value === code)?.label})`
}

export const getProductId = (product: CartItem | WishlistItem | ShareCartItem | ProductAlgolia): string => {
  return product.customization?.identifier || product.sku
}
