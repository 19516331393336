import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const firebaseConfig = config.public.firebase

  const app = initializeApp(firebaseConfig)
  const functions = getFunctions(app, 'europe-north1')
  const auth = getAuth(app)
  const firestore = getFirestore(app)

  if (import.meta.dev && config.public.FIREBASE_EMULATORS) {
    connectFirestoreEmulator(firestore, 'localhost', 9090)
    connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: process.env.NODE_ENV === 'development' })
    connectFunctionsEmulator(functions, 'localhost', 5002)
  }

  nuxtApp.vueApp.provide('firebaseApp', app)
  nuxtApp.provide('firebaseApp', app)

  nuxtApp.vueApp.provide('functions', functions)
  nuxtApp.provide('functions', functions)

  nuxtApp.vueApp.provide('auth', auth)
  nuxtApp.provide('auth', auth)

  // console.log(auth)

  nuxtApp.vueApp.provide('firestore', firestore)
  nuxtApp.provide('firestore', firestore)

  // test:
  // nuxtApp.provide('firebase', {
  //   auth,
  //   firestore,
  //   functions
  // })
})
