import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import type { ToastState, Toast } from '~/types/toast'

export const useToastStore = defineStore('toast-store', {
  state: (): ToastState => ({
    toasts: []
  }),
  actions: {
    addToast(toast: Toast) {
      this.toasts.push({ id: uuidv4(), ...toast })
    },
    removeToast(id: string) {
      this.toasts = this.toasts.filter((t: Toast) => t.id !== id)
    },
    clearToasts() {
      this.$reset()
    }
  }
})
