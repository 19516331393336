import { defineStore } from 'pinia'
import { getVariationImage } from '~/helpers/product'
import { useToastStore } from '~/stores/toast'
import type { QuoteCartItem } from '~/types/item'
import type { ProductAlgolia } from '~/types/product'
import type { QuotePayload } from '~/types/quoteCart'

export interface ContactInfo {
  firstname: string
  lastname: string
  email: string
  phone: string
  businessCustomer: boolean
  message: string
  company?: string // Optional, as it might not be relevant for non-business customers
  vatNumber?: string // Optional, as it might not be relevant for non-business customers
}

const emptyContactInfo = () => ({
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  businessCustomer: false,
  message: '',
  company: '',
  vatNumber: ''
})

export const useQuoteCartStore = defineStore('quote-cart', {
  state: () => ({
    items: [] as QuoteCartItem[],
    contactInfo: emptyContactInfo(),
    sendStatus: 'unsent' as 'unsent' | 'sending' | 'sent' | 'error'
  }),
  getters: {
    getQuoteCartItemBySku: state => (sku: QuoteCartItem['sku']): QuoteCartItem | undefined => state.items.find((item: QuoteCartItem) => item.sku === sku),
    totalItemAmount: state => state.items.reduce((sum: number, item: QuoteCartItem) => sum + item.amount, 0)
  },
  actions: {
    /**
     * Add a product to the quote cart
     * @param product
     * @param options
     */
    async addToQuoteCart(product: ProductAlgolia, options: { amount?: number, notifyUser?: boolean } = {}) {
      // Options
      const _options = { amount: 1, notifyUser: true, ...options }
      // Quote cart item
      const quoteCartItem: QuoteCartItem = {
        version: 1,
        baseType: 'quoteCartItem',
        id: product.sku,
        sku: product.sku,
        title: product.name.default,
        variationName: product.name.variation,
        slug: product.slug,
        amount: _options.amount,
        variationOptions: product.selectableVariationAttributes,
        image: getVariationImage(product, 'thumbnail')
      }

      // Add or increase the amount of the item in the quote cart
      await this.addQuoteCartItem(quoteCartItem)

      // Send toast notification
      if (_options.notifyUser) {
        useToastStore().addToast({
          message: (quoteCartItem.amount > 1 ? quoteCartItem.amount + ' kpl' : 'Tuote') + ' lisätty tarjouskoriin!',
          type: 'success',
          ttl: 5000,
          cta: { label: 'Siirry tarjouskoriin', to: '/tarjouskori' }
        })
      }
    },
    /**
     * Add a quote cart item to the quote cart.
     * If the item already exists in the quote cart, increase the amount.
     * @param quoteCartItem
     */
    async addQuoteCartItem(quoteCartItem: QuoteCartItem) {
      const existingProduct = this.items.find(item => item.sku === quoteCartItem.sku)
      if (existingProduct) {
        existingProduct.amount += quoteCartItem.amount
      }
      else {
        this.items.push(quoteCartItem)
      }
    },
    /**
     * Remove an item from the quote cart by id
     * @param id
     */
    async removeFromQuoteCart(id: QuoteCartItem['id']) {
      const itemIndex = this.items.findIndex((item: QuoteCartItem) => item.id === id)
      if (itemIndex !== -1) {
        this.items.splice(itemIndex, 1)
      }
    },
    /**
     * Remove all items in the quote cart
     */
    clearItems() {
      this.items = []
    },
    /**
     * Clear the quote cart
     */
    clearContactInfo() {
      this.contactInfo = emptyContactInfo()
    },
    /**
     * @TODO: Send the quote request via email
     */
    async sendQuoteRequest(): Promise<void> {
      this.sendStatus = 'sending'
      const payload: QuotePayload = {
        items: this.items,
        ...this.contactInfo
      }

      const quoteResponse = await $fetch<{ success: boolean, status: 'sent' | 'error', mailchimpResponse?: string }>('/api/send-quote', { method: 'post', body: payload })
      if (quoteResponse.success) {
        this.clearContactInfo()
        this.sendStatus = 'sent'
      }
      else {
        this.sendStatus = 'error'
      }
    }
  },
  persist: {
    key: 'quote-cart-store',
    storage: piniaPluginPersistedstate.localStorage(),
    pick: ['items']
  }
})
