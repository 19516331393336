<template>
  <component
    :is="element"
    :class="classes"
    :to="to"
  >
    <template v-if="hasMultipleStates">
      <TransitionGroup :name="transition">
        <span
          v-for="s in states"
          :key="getStateKey(s)"
          :class="{ 'opacity-0 invisible': !isCurrentState(s) }"
          :aria-hidden="!isCurrentState(s)"
          class="flex items-center justify-center gap-2"
        >
          <slot :name="s" />
        </span>
      </TransitionGroup>
    </template>

    <slot v-else />
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  look: {
    type: String as () => 'primary' | 'ghost' | 'outline' | 'link' | 'outline-invert' | 'transactional' | 'secondary' | 'tertiary' | 'blank',
    required: false,
    default: 'primary',
    validator: (prop: string) => ['primary', 'ghost', 'outline', 'link', 'outline-invert', 'transactional', 'secondary', 'tertiary', 'blank'].includes(prop)
  },
  transition: {
    type: String,
    required: false,
    default: 'cube-flip-2em'
  },
  state: {
    type: String,
    required: false,
    default: 'default'
  },
  to: {
    type: String,
    required: false,
    default: null
  },
  tag: {
    type: String,
    required: false,
    default: ''
  }
})

const attrs = useAttrs()
const slots = useSlots()

const states = computed(() => Object.keys(slots))
const element = computed(() => {
  if (props.tag) {
    return props.tag
  }
  else if (attrs.href) {
    return 'a'
  }
  else if (props.to) {
    return resolveComponent('NuxtLink')
  }
  else {
    return 'button'
  }
})
const lookClass = computed(() => props.look ? `btn-${props.look}` : '')
const currentState = computed(() => states.value.includes(props.state) ? props.state : 'default')
const currentStateClass = computed(() => `btn-state-${currentState.value}`)
const hasMultipleStates = computed(() => states.value.length > 1)
const classes = computed(() => ['btn', lookClass.value, currentStateClass.value, { '*:col-start-1 *:row-start-1': hasMultipleStates.value }])

const getStateKey = (state: string) => {
  return state + (currentState.value === state ? '_active' : '')
}
const isCurrentState = (state: string) => {
  return currentState.value === state
}
</script>
