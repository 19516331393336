<template>
  <nav class="size-full relative z-50 inset-y-0 right-auto shadow-lg overflow-hidden bg-surface-raised duration-300">
    <div ref="mainmenu" class="overflow-y-auto overscroll-contain overflow-x-hidden absolute inset-x-0 h-full w-full transition-[transform,_opacity]">
      <section class="sticky z-20 px-4 flex justify-between items-center top-0 bg-gold-100 text-pine-950 h-16">
        <img
          src="~assets/logo/logo.svg"
          aria-hidden="true"
          alt="Tarvikeskus"
          class="h-4 dark:invert"
        >
        <h2 class="sr-only">
          {{ $t('menu') }}
        </h2>

        <BaseButton look="blank" class="text-pine-950 hover:bg-gold-50 size-12 p-0" @click="close">
          <XMarkIcon class="size-6" />
        </BaseButton>
      </section>

      <section class="bg-surface-raised px-4 py-6">
        <h3 class="beta mb-4">
          Tuotteet
        </h3>
        <ul>
          <li v-for="item in categoryTree" :key="item.id">
            <MainMenuItem
              v-if="item.path"
              :item="item"
              :name-class="item.name === 'Tarjoukset' ? 'text-red-600' : ''"
              @click.capture="(event) => openSubmenu(event, item)"
            />
          </li>
        </ul>
      </section>

      <div class="grid py-6 gap-10 bg-gold-100 px-4">
        <ul class="grid">
          <li v-for="link in otherLinks" :key="link.path">
            <NuxtLink :to="link.path" class="link-unstyled w-full flex px-2 justify-between items-center h-12 rounded hover:bg-gold-50 active:bg-gold-50">
              <span class="font-medium flex-1 text-left truncate">
                {{ link.name }}
              </span>
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div class="bg-black py-10 text-center">
        <BaseButton look="blank" class="bg-pine-950 hover:bg-pine-900 active:bg-pine-800 text-gold-50" @click="close">
          <span class="flex items-center gap-2">
            <XMarkIcon class="size-6" /><span>Sulje valikko</span>
          </span>
        </BaseButton>
      </div>
    </div>
    <MainMenuSubPanel
      ref="submenu"
      :item="history"
      :transition="transition"
      @menu-item-opened="setCurrentMenu"
      @menu-back="back"
    />
  </nav>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { useCategoryStore } from '~/stores/category'
import { useUiStore } from '~/stores/ui'
import type { CategoryTreeItem } from '~/types/category'

const categoryStore = useCategoryStore()
const uiStore = useUiStore()
const route = useRoute()
const router = useRouter()

const mainmenu = ref<any>(null)
const submenu = ref<any>(null)

const forward = ref(true)
const history = ref<any[]>([])

const transition = computed(() => forward.value ? 'full-slide-left' : 'full-slide-right')
const categoryTree = computed(() => (categoryStore.getCategoryTree || []).filter((cat: CategoryTreeItem) => cat.isIncludedInMenu === true))
const isMainMenuOpen = computed(() => uiStore.isMainMenuOpen)

const otherLinks = [
  { name: 'Kampanjat', path: '/kampanjat' },
  { name: 'Varaosahaku', path: '/varaosahaku' },
  { name: 'Vaihtolaitteet', path: '/vaihtolaitteet' },
  { name: 'Huoltopalvelut', path: '/cms/palvelut/huoltopalvelut' },
  { name: 'Brändit', path: '/category/brandit' },
  { name: 'Asiakaspalvelu', path: '/cms/asiakaspalvelu' }
]

watch(() => route.path, () => {
  // Close menu when route changes
  uiStore.closeMainMenu()
})
watch(() => isMainMenuOpen.value, (isOpen) => {
  if (!isOpen) {
    close()
  }
})
onBeforeUnmount(() => {
  close()
})

const setCurrentMenu = (cat: any) => {
  forward.value = true
  if (submenu.value.$el.nodeName !== '#comment') {
    submenu.value.$el.scrollTo({
      top: 0,
      left: 0
    })
  }
  const categoryPath = '/' + cat.path
  if (cat.children?.length) {
    history.value.push(cat)
  }
  else if (route.path === categoryPath) {
    close()
  }
  else {
    router.push({ path: categoryPath })
  }
}
const close = () => {
  uiStore.closeMainMenu()
  setTimeout(() => {
    if (mainmenu.value) {
      mainmenu.value.scrollTo({ top: 0, left: 0 })
    }
    history.value = []
  }, 500)
}
const openSubmenu = (event: Event, cat: CategoryTreeItem) => {
  if (cat.children.length > 0 && cat.children.some((c: CategoryTreeItem) => c.isIncludedInMenu)) {
    event.preventDefault()
    forward.value = true
    history.value.push(cat)
  }
}
const back = () => {
  forward.value = false
  submenu.value.$el.scrollTo({
    top: 0,
    left: 0
  })
  history.value.pop()
}
</script>
