<template>
  <Transition name="mainmenu-transition" :duration="500">
    <div v-show="isMainMenuOpen" class="fixed size-full inset-0 z-50">
      <div class="mainmenu-overlay bg-overlay absolute size-full inset-0" @click="isMainMenuOpen = false" />
      <div ref="menu" class="w-[90vw] max-w-[500px] h-full" tabindex="0">
        <MainMenuPanel class="mainmenu" />
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { useUiStore } from '~/stores/ui'

const menu = ref<HTMLElement | null>(null)

const { isMainMenuOpen } = storeToRefs(useUiStore())
const { bodyScrollLocked } = useBodyScrollLock()

watch(() => isMainMenuOpen.value, async (open) => {
  if (open) {
    bodyScrollLocked.value = true
    await nextTick()
    setTimeout(() => {
      menu.value?.focus()
    }, 500)
  }
  else {
    bodyScrollLocked.value = false
  }
})
</script>

<style lang="scss">
.mainmenu-transition {
  &-enter,
  &-enter-from,
  &-leave-to {
    .mainmenu-overlay {
      opacity: 0;
    }
    .mainmenu {
      transform: translateX(-100%)
    }
  }
  &-enter-active,
  &-leave-active {
    overflow-y: hidden !important;
    .mainmenu-overlay {
      transition: opacity 300ms ease;
    }
    .mainmenu {
      transition: transform 500ms ease;
    }
  }
}
</style>
