import validate from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/pages/runtime/validate.js";
import breadcrumbs_45global from "/src/nuxt-app/middleware/breadcrumbs.global.ts";
import manifest_45route_45rule from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  breadcrumbs_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/src/nuxt-app/middleware/auth.ts"),
  "verify-email": () => import("/src/nuxt-app/middleware/verifyEmail.ts")
}