export function usePrice() {
  // todo, this should be validated that it will get correct amounts, best practice is to unit test it.
  const getWithOutTaxSum = (sum: number, vatRate = 25.5): number => {
    return sum / (1 + vatRate / 100)
  }

  const getDiscountPercent = (price: number, finalPrice: number): number => Math.round(100 - ((finalPrice / price) * 100))

  return {
    getWithOutTaxSum,
    getDiscountPercent
  }
}
