import { defineStore } from 'pinia'
import type { MarketingPermissions, UserAddress, UserDetails, UserState, Wishlist } from '~/types/user'
import type { ProductAlgolia, ProductImage, ProductImageVariants } from '~/types/product'
// import { useAuth, useFirestore, useReauth } from '~/composables/useFirebase'
// import { useToastStore } from '~/stores/toast'
// import { useCartStore } from '~/stores/cart'
import { getProductId } from '~/helpers/customization'
import type { WishlistItem } from '~/types/item'
// import { getVariationImage, formatItemVersion } from '~/helpers/product'

export const formatItemVersion = (itemVersionStr: string): number => {
  // @ts-ignore
  return parseInt(itemVersionStr || '') || 1
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    loggedIn: false,
    user: null,
    details: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      addresses: [],
      marketingPermissions: {
        sms: false,
        email: false
      },
      wishlists: [],
      registerEmail: '',
      dob: null
    },
    orders: [],
    orderListStatus: 'initial',
    loadingMarketingPermissions: true
  }),
  getters: {
    // loggedIn: state => state.loggedIn,
    uid: state => state.user?.uid,
    // details: state => state.details,
    firstname: state => state.details?.firstname,
    isLoadingMarketingPermissions: state => state.loadingMarketingPermissions,
    lastname: state => state.details?.lastname,
    initials: state => `${state.details?.firstname[0]}${state.details?.lastname[0]}`,
    addresses: state => state.details?.addresses,
    city: state => state.details?.addresses?.[0]?.city,
    zip: state => state.details?.addresses?.[0]?.postcode,
    phone: state => state.details?.phone,
    email: state => state.details?.email,
    userDetails: state => state.details,
    marketingPermissions: state => state.details?.marketingPermissions,
    isEmptyAddressBook: state => !state.details?.addresses?.length,
    defaultBillingAddress: state => state.details?.addresses?.find(addr => addr.defaultBilling),
    defaultShippingAddress: state => state.details?.addresses?.find(addr => addr.defaultShipping),
    addressById: state => (id: string) => state.details?.addresses?.find(addr => addr.id === id),
    userOrders: state => [...state.orders].sort((a, b) => new Date(b.order.created_at).getTime() - new Date(a.order.created_at).getTime()),
    userOrderByOrderNumber: state => (orderNumber: string) => state.orders?.find(order => order.order.order_number === orderNumber),
    wishlists: state => state.details?.wishlists,
    wishlistById: state => (id: string) => state.details?.wishlists?.find(wishlist => wishlist.id === id),
    totalWishlistItemAmount: state => state.details?.wishlists?.reduce((total, wishlist) => total + wishlist.items.length, 0),
    wishlistsContainOutdatedItems: state => (version: number): boolean => state.details?.wishlists?.length ? state.details.wishlists.some((wl: Wishlist) => wl.items.some(item => (!item.version || item.version !== version))) : false
  },
  actions: {
    setUserDetails(payload: UserDetails) {
      let details: UserDetails
      // TODO why here is this check?
      if (payload) {
        details = {
          firstname: payload.firstname,
          lastname: payload.lastname,
          email: payload.email,
          registerEmail: payload.registerEmail,
          phone: payload.phone,
          addresses: payload.addresses,
          marketingPermissions: payload.marketingPermissions,
          wishlists: payload.wishlists
        }
        this.details = details
      }
    },
    async getMarketingPermissions() {
      this.loadingMarketingPermissions = true
      const { getMarketingPermissionsStatusHandler } = useFirebase()
      const response = await getMarketingPermissionsStatusHandler()
      if (response) {
        this.details.marketingPermissions = response
      }
      this.loadingMarketingPermissions = false
    },
    async updateUserDetails(payload: any) {
      const updatedDetails = { ...this.details, ...payload }
      this.setUserDetails(updatedDetails)
      const { updateUserDocument } = useFirebase()
      // Update Firestore
      return await updateUserDocument(updatedDetails)
      // if (isUpdateSuccess) {
      //   // Update CRM
      //   const updateCustomerDataPlatform = this.$fire.functions.httpsCallable('veke3000-customer-updateCustomerDataPlatform')
      //   updateCustomerDataPlatform({ ...updatedDetails, email: this.$fire.auth.currentUser?.email })
      //     .catch((updateCustomerDataPlatformError) => {
      //       console.error('CDP update failed', updateCustomerDataPlatformError)
      //       return false
      //     })
      //
      //   this.$store.dispatch(ToastActions.addToast, { message: 'Tietosi päivitettiin', type: 'success', ttl: 3000 })
      //   return true
      // }
      // else {
      //   return false
      // }
    },
    async sendLoginEmailUpdateVerification(form: any) {
      console.log('form: ', form)
      const { reauthenticateUser, updateEmail } = useFirebase()
      const updateAuthEmail = async (psw: string, email: string) => {
        return await reauthenticateUser(psw)
          .then(async (success) => {
            console.log('reauth: ', success)
            if (success) {
              return await updateEmail(email)
            }
            else {
              // this.clearState()
              console.error('Cannot verify email')
              return false
            }
          })
      }
      return await updateAuthEmail(form.password, form.email)
        .then((success) => {
          if (success) {
            console.log('updateAuthEmail: ', success)
            console.log('updated email')
            // this.$store.dispatch(ToastActions.addToast, { message: `Sait sähköpostia osoitteeseen ${payload.email}!`, type: 'success', ttl: 3000 })
            return true
          }
          else {
            console.log('update failed')
            // this.$store.dispatch(ToastActions.addToast, { message: 'Tapahtui virhe! Tarkasta salasanasi.', type: 'error', ttl: 3000 })
            return false
          }
        })
    },
    async addAddress(payload: UserAddress) {
      const { addAddress } = useFirebase()
      const { addToast } = useToastStore()
      const address = { ...payload }

      return await addAddress(address)
        .then(({ id }) => {
          address.id = id
          this.details.addresses.push(address)
          addToast({ message: 'Osoite lisätty!', type: 'success', ttl: 3000 })
          return true
        })
        .catch((err) => {
          console.error(err)
          return false
        })
    },
    async updateAddress(payload: UserAddress) {
      const { updateAddress } = useFirebase()
      const { addToast } = useToastStore()

      return await updateAddress(payload).then(() => {
        this.details.addresses = this.details.addresses.map(a => a.id !== payload.id ? a : { ...a, ...payload })
        addToast({ message: 'Osoite päivitetty!', type: 'success', ttl: 3000 })
        return true
      })
        .catch((err: any) => {
          console.error(err)
          return false
        })
    },
    async removeAddress(addressId: string) {
      const { removeAddress } = useFirebase()
      const { addToast } = useToastStore()

      return await removeAddress(addressId).then(() => {
        // @ts-ignore
        this.details.addresses = this.details.addresses.filter(address => address.id !== addressId)
        addToast({ message: 'Osoite poistettu!', type: 'success', ttl: 3000 })
        return true
      })
        .catch((err: any) => {
          addToast({ message: 'Osoitteen poisto epäonnistui! Yritä uudelleen.', type: 'error', ttl: 3000 })
          console.error(err)
          return false
        })
    },
    onAuthStateChanged({ authUser }: { authUser: any }) {
      if (!authUser) {
        this.user = null
      }
      else {
        this.user = authUser
      }
    },
    setLoggedInStatus(payload: boolean) {
      this.loggedIn = payload
      // this.$store.dispatch(CartActions.fetchCartRule)
    },
    async updateUserMarketingPermissions(payload: MarketingPermissions): Promise<boolean> {
      const { updateUserMarketingPermissions } = useFirebase()
      const { addToast } = useToastStore()

      await updateUserMarketingPermissions(payload)
        .then(() => {
          this.details.marketingPermissions.sms = payload.sms
          this.details.marketingPermissions.email = payload.email
          addToast({ message: 'Markkinointilupasi on päivitetty!', type: 'success', ttl: 3000 })

          // This call is handled in server middleware (marketing-permissions-update) TODO this should be done some other way maybe?
          // axios.post('/api/marketing-permissions-update', { email: this.$fire.auth.currentUser?.email, marketingPermissions: payload })
          //   .catch((err: any) => {
          //     // Sentry.captureException(updateCustomerDataPlatformError)
          //     console.error('updateCustomerDataPlatform', err)
          //     return false
          //   })
        })
        .catch((err: any) => {
          // Sentry.captureException(err)
          console.error(err)
          addToast({ message: 'Voi ei, jotain meni pieleen. Yritä uudelleen.', type: 'error', ttl: 3000 })
          return false
        })

      return true
    },
    // clearState() {
    //   this.loggedIn = false
    //   this.user = null
    //   this.details = null
    //   this.orders = null
    //   this.orderListStatus = 'initial'
    // },
    async fetchUserOrders() {
      const { getUserOrders } = useFirebase()
      // initialize orders
      this.orders = []
      this.orderListStatus = 'fetching'
      const orders = await getUserOrders().catch((err) => {
        console.error(err)
        this.orderListStatus = 'error'
      })

      if (orders) {
        this.orders = orders
        this.orderListStatus = 'fetched'
      }
      else {
        this.orderListStatus = 'error'
      }
    },
    // async fetchWishlistItems() {
    //   const { getWishlists } = useFirestore(this.$fire)

    //   const wishlists = await getWishlists()
    //   const updatedWishlists = wishlists.map((wishlist) => {
    //     const items = wishlist.items.map((item) => {
    //       return {
    //         id: item.id,
    //         name: item.name,
    //         image: item.image,
    //         price: item.price,
    //         version: formatItemVersion(item),
    //         variant: item.variant,
    //         productId: getProductId(item),
    //         isOutdated: item.isOutdated,
    //         outdated: item.outdated,
    //         image: getVariationImage(item)
    //       }
    //     })
    //     return { ...wishlist, items }
    //   })
    //   this.details.wishlists = updatedWishlists
    // },
    async removeWishlistItem(payload: any) {
      console.log('remove wishlist item: ', payload)
      // const { removeWishlistItem } = useFirebase()
      //
      // return await removeWishlistItem(payload).then(() => {
      //   const wishlist = this.details.wishlists.find(w => w.id === payload.wishlistId)
      //   wishlist.items = wishlist.items.filter(item => item.id !== payload.itemId)
      //   addToast({ message: 'Tuote poistettu toivelistalta!', type: 'success', ttl: 3000 })
      //   return true
      // }).catch((err: any) => {
      //   console.error(err)
      //   return false
      // })
    },
    // async moveWishlistItemToCart(payload) {
    //   const { moveWishlistItemToCart } = useFirestore(this.$fire)

    //   return await moveWishlistItemToCart(payload).then(() => {
    //     const wishlist = this.details.wishlists.find(w => w.id === payload.wishlistId)
    //     wishlist.items = wishlist.items.filter(item => item.id !== payload.itemId)
    //     this.$store.dispatch(ToastActions.addToast, { message: 'Tuote siirretty toivelistalta ostoskoriin!', type: 'success', ttl: 3000 })
    //     return true
    //   }).catch((err) => {
    //     console.error(err)
    //     return false
    //   })
    // },
    // async addWishlistItem(payload) {
    //   const { addWishlistItem } = useFirestore(this.$fire)

    //   return await addWishlistItem(payload).then(() => {
    //     const wishlist = this.details.wishlists.find(w => w.id === payload.wishlistId)
    //     wishlist.items.push(payload.item)
    //     this.$store.dispatch(ToastActions.addToast, { message: 'Tuote lisätty toivelistalle!', type: 'success', ttl: 3000 })
    //     return true
    //   }).catch((err) => {
    //     console.error(err)
    //     return false
    //   })
    // },
    // async createWishlist(payload) {
    //   const { createWishlist } = useFirestore(this.$fire)

    //   return await createWishlist(payload).then(({ id }) => {
    //     this.details.wishlists.push({ ...payload, id, items: [] })
    //     this.$store.dispatch(ToastActions.addToast, { message: 'Toivelista luotu!', type: 'success', ttl: 3000 })
    //     return true
    //   }).catch((err) => {
    //     console.error(err)
    //     return false
    //   })
    // }
    async clearWishlist(wishlistId: string) {
      console.log('crear wishlist: ', wishlistId)
      // Update Firestore by calling useFirebase.updateWishlist(wishlist: Wishlist)-function
      // const wishlist: Wishlist|undefined = getters.wishlistById(wishlistId)
      // if (wishlist) {
      //   // Remove all items from the wishlist
      //   commit('CLEAR_WISHLIST', wishlistId)
      //   const { updateWishlist } = useFirestore(this.$fire)
      //
      //   // Update wishlist to Firestore
      //   await updateWishlist(wishlist)
      //     .then(() => {
      //       addToast({
      //         message: `Toivelista "${wishlist.name}" tyhjennetty!`,
      //         type: 'success',
      //         ttl: 3000
      //       })
      //     })
      //     .catch(() => addToast({ message: 'Toivelistan tyhjennys epäonnistui', ttl: 3000, type: 'error' }))
      // }
    },
    async removeWishlist(wishlist: Wishlist) {
      const { deleteWishlist } = useFirebase()
      const { addToast } = useToastStore()
      await deleteWishlist(wishlist.id!)
        .then(() => {
          const i = this.details?.wishlists?.map(wishlist => wishlist.id).indexOf(wishlist.id)
          if (i >= 0) {
            this.details?.wishlists?.splice(i, 1)
          }
          addToast({
            message: `Toivelista "${wishlist.name}" poistettu!`,
            type: 'success',
            ttl: 3000
          })
        })
        .catch((e: any) => {
          console.error(e)
          addToast({ message: 'Toivelistan poistaminen epäonnistui!', ttl: 3000, type: 'error' })
        })
    },
    ADD_WISHLIST(payload: Wishlist) {
      this.details?.wishlists.push(payload)
    },
    ADD_ITEM_TO_WISHLIST(payload: { wishlistId: string, product: ProductAlgolia }) {
      const wishlist = this.details.wishlists.find(wl => wl.id === payload.wishlistId)
      if (wishlist) {
        const config = useRuntimeConfig()
        const wishlistItem: WishlistItem = {
          id: getProductId(payload.product),
          // @ts-ignore
          version: formatItemVersion(config.public.ITEM_VERSION_WISHLISTITEM),
          baseType: 'wishlistItem',
          sku: payload.product.sku,
          amount: 1,
          title: payload.product.name.default,
          image: getVariationImage(payload.product, 'small'),
          slug: payload.product.slug
        }

        if (payload.product.customization) {
          wishlistItem.customization = payload.product.customization
        }
        wishlist.items.push(wishlistItem)
      }
    },
    UPDATE_WISHLIST_ITEM(payload: { wishlistId: string, item: WishlistItem }) {
      const wishlist = this.details.wishlists.find((wl: Wishlist) => wl.id === payload.wishlistId) || null
      if (wishlist) {
        wishlist.items = [...wishlist.items.map(item => item.id !== payload.item.id ? item : { ...item, ...payload.item })]
      }
    },
    REMOVE_ITEM_FROM_WISHLIST(payload: { wishlistId: string, item: WishlistItem }) {
      const wishlist = this.details.wishlists.find(wl => wl.id === payload.wishlistId)
      if (wishlist) {
        wishlist.items = wishlist.items.filter(item => item.id !== payload.item.id) || []
      }
    },
    async renameWishlist(payload: { wishlistId: string, name: string }) {
      const { updateWishlist } = useFirebase()
      const { addToast } = useToastStore()
      const wishlist = this.details?.wishlists?.find((wl: Wishlist) => wl.id === payload.wishlistId) || null
      if (wishlist) {
        // Update wishlist name to state
        wishlist.name = payload.name
        // Update wishlist name to Firestore
        await updateWishlist(wishlist)
          .then(() => {
            addToast({
              message: 'Toivelistan nimi päivitetty!',
              type: 'success',
              ttl: 3000
            })
          })
          .catch(() => addToast({ message: 'Toivelistan päivittäminen epäonnistui!', ttl: 3000, type: 'error' }))
      }
    },
    async removeItemFromWishlist(payload: { wishlistId: string, item: WishlistItem }) {
      const { addToast } = useToastStore()
      const wishlist: Wishlist | undefined = this.wishlistById(payload.wishlistId)
      if (!wishlist) {
        addToast({
          message: 'Toivelistaa ei löytynyt!',
          type: 'error',
          ttl: 3000
        })
        return
      }

      // Remove the given item from the wishlist
      this.REMOVE_ITEM_FROM_WISHLIST(payload)
      // Update Firestore by calling useFirebase.updateWishlist(wishlist: Wishlist)-function
      const { updateWishlist } = useFirebase()

      // Update wishlist to Firestore
      await updateWishlist(wishlist)
        .then(() => {
          addToast({
            message: 'Tuote poistettu toivelistalta!',
            type: 'success',
            ttl: 3000
          })
        })
        .catch(() => addToast({ message: 'Tuotteen poistaminen toivelistasta epäonnistui', ttl: 3000, type: 'error' }))
    },
    async addItemToWishlist(payload: { wishlistId?: string, product: ProductAlgolia }) {
      const { addToast } = useToastStore()
      // Load or create a new wishlist
      const wishlist: Wishlist | undefined | null = (!payload.wishlistId) ? await this.createWishlist({ name: 'Toivelista' }) : this.wishlistById(payload.wishlistId)

      if (!wishlist) {
        addToast({
          message: 'Toivelistaa ei löytynyt!',
          type: 'error',
          ttl: 3000
        })
        return
      }

      // Convert given product to a wishlist item and add to the wishlist
      this.ADD_ITEM_TO_WISHLIST({ wishlistId: wishlist.id!, product: payload.product })

      const { updateWishlist } = useFirebase()
      // Update wishlist to Firestore
      await updateWishlist(wishlist)
        .then(() => {
          addToast({
            message: `Tuote lisätty toivelistalle "${wishlist.name}"`,
            type: 'success',
            ttl: 5000,
            cta: { label: 'Siirry toivelistalle', to: `/oma-tili/toivelistat/${wishlist.id}` }
          })
        })
        .catch(() => addToast({ message: 'Tuotteen lisääminen toivelistaan epäonnistui', ttl: 3000, type: 'error' }))
    },
    async updateItemInWishlists(payload: { wishlistIds: string[], product: ProductAlgolia }): Promise<boolean> {
      const { updateWishlist } = useFirebase()
      const { addToast } = useToastStore()
      let hasErrors = false
      let updatedListCount = 0

      // Loop through all wishlists in state
      for (const wishlist of this.details.wishlists) {
        const shouldItemBeAddedOnCurrentWishlist = payload.wishlistIds.includes(wishlist.id!)
        // Primarily find wishlist item by customization ID and secondarily by product SKU
        const item = wishlist.items.find(item => item.id === getProductId(payload.product))

        if (shouldItemBeAddedOnCurrentWishlist && !item) {
          // Add the given item on a wishlist if wishlist ID is in wishlistIds-array and item is NOT already on the wishlist
          this.ADD_ITEM_TO_WISHLIST({ wishlistId: wishlist.id!, product: payload.product })
          await updateWishlist(wishlist).catch(() => hasErrors = true)
          updatedListCount++
        }
        else if (!shouldItemBeAddedOnCurrentWishlist && item) {
          // Remove the given item from a wishlist if wishlist ID not in wishlistIds-array AND wishlist contains the item
          this.REMOVE_ITEM_FROM_WISHLIST({ wishlistId: wishlist.id!, item })
          await updateWishlist(wishlist).catch(() => hasErrors = true)
          updatedListCount++
        }
      }

      if (hasErrors) {
        addToast({ message: 'Toivelistojen päivitys epäonnistui!', ttl: 3000, type: 'error' })
        return false
      }
      else {
        const message = updatedListCount > 1 ? `${updatedListCount} toivelistaa päivitetty!` : 'Toivelista päivitetty!'
        addToast({
          message,
          type: 'success',
          ttl: 3000
        })
        return true
      }
    },
    async createWishlist(payload: { name: string }): Promise<Wishlist | null> {
      const { addWishlist } = useFirebase()
      const { addToast } = useToastStore()
      // Create wishlist and add to Firestore
      const wishlist = await addWishlist(payload?.name)
      if (wishlist) {
        // Add created wishlist to state
        this.ADD_WISHLIST(wishlist)
        // Celebrate success
        addToast({
          message: `Toivelista "${wishlist.name}" luotu!`,
          type: 'success',
          ttl: 5000,
          cta: { label: 'Siirry toivelistalle', to: `/oma-tili/toivelistat/${wishlist.id}` }
        })
      }
      else {
        // Sound alarm on error
        addToast({ message: 'Uuden toivelistan luonti epäonnistui!', ttl: 3000, type: 'error' })
      }

      return wishlist
    }
  },
  persist: {
    key: 'user-store',
    storage: piniaPluginPersistedstate.localStorage()
  }
})

export const getUrlFromImage = (image: ProductImage, variant?: keyof ProductImageVariants): string => (variant && image.variants) ? (image.variants[variant] || image.url || '') : image.url || ''

export const getMainImage = (product: ProductAlgolia, variant?: keyof ProductImageVariants) => {
  const image = product?.defaultImage || product?.defaultVariationImage
  return image ? getUrlFromImage(image, variant) : ''
}
export const getVariationImage = (product: ProductAlgolia, variant?: keyof ProductImageVariants) => {
  const image = product?.defaultVariationImage || product?.images[0]
  return image ? getUrlFromImage(image, variant) : ''
}
export const getVariationImageMasked = (product: ProductAlgolia, variant?: keyof ProductImageVariants) => {
  const image = product?.images.find((image: ProductImage) => !image.shotAngle?.toLowerCase().includes('miljöö')) || product?.defaultVariationImage
  return image ? getUrlFromImage(image, variant) : ''
}
export const getUpholsteryImages = (product: ProductAlgolia) => product?.images.filter((image: ProductImage) => image.shotAngle?.toLowerCase().includes('verhoilunäyte'))
