import { useCategoryStore } from '@/stores/category'

export default defineNuxtPlugin(async (nuxtApp: any) => {
  const categoriesStore = useCategoryStore(nuxtApp.$pinia)
  const fetchCategories = async () => {
    await categoriesStore.fetchCategories() // 'https://storage.googleapis.com/media-veke-fi')
    categoriesStore.buildCategoryTree()
  }
  // Server-side logic
  if (import.meta.server) {
    await fetchCategories()
  }
  // Client-side logic
  if (import.meta.client && !categoriesStore.isFetched) {
    await fetchCategories()
  }
})
