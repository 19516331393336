<template>
  <NuxtLink :to="'/product/' + item.slug" data-test="search-item-product" class="text-current px-5 py-4 text-sm block hover:bg-grey-50 hover:no-underline">
    <div class="flex gap-4">
      <img
        :src="getMainImageUrl(item, 'thumbnail')"
        :alt="productName(item)"
        class="size-14 object-contain bg-white ring-2 ring-white"
      >
      <div class="flex-1 flex flex-col gap-1">
        <div class="text-default">
          {{ productName(item) }}
        </div>
        <div>
          <ReviewStars :score="reviewScore" class="h-2" />
        </div>
        <ProductPriceDisplay
          class="text-xxs"
          :is-starting-price="hasVariantPriceRange(item)"
          :price="lowestVariantPrice(item)"
          :final-price="lowestVariantFinalPrice(item)"
          show-discount-percent
        />
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { ProductTileAlgolia } from '~/types/product'
import { useProduct } from '~/composables/useProduct'
import ReviewStars from '~/components/product/ReviewStars.vue'

// Props
const props = defineProps({
  item: {
    type: Object as PropType<ProductTileAlgolia>,
    required: true
  }
})

// Emits
defineEmits(['clear-search'])

const reviewScore = computed(() => props.item?.yotpo?.reviewScore || 0)

const {
  productName,
  getMainImageUrl,
  hasVariantPriceRange,
  lowestVariantFinalPrice,
  lowestVariantPrice
} = useProduct()
</script>
