<template>
  <TransitionGroup
    name="toast-transition"
    appear
    tag="div"
    data-jest="toasts"
    class="toasts fixed z-50 bottom-0 lg:bottom-auto lg:right-4 inset-x-0 lg:left-auto lg:top-28"
  >
    <ToastNotification v-for="toast in toasts" :key="toast.id" :toast="toast" />
  </TransitionGroup>
</template>

<script setup lang="ts">
import ToastNotification from '~/components/toast-notifications/ToastNotification.vue'
import { useToastStore } from '~/stores/toast'

// Use store instance
const toastStore = useToastStore()

// Get toasts using computed property
const toasts = computed(() => toastStore.toasts)
</script>

<style lang="scss" scoped>
.toasts {
  @apply grid lg:flex lg:items-end lg:flex-col-reverse gap-3;

  .toast.toast-transition {
    &-enter,
    &-enter-from {
      @apply transform z-10 relative translate-y-full;
      @screen lg {
        @apply opacity-100 -translate-y-2;
      }
    }
    &-leave-to {
      @apply transform translate-y-full z-0 relative;
      @screen lg {
        @apply opacity-0 translate-y-2;
      }
    }
    &-leave-active,
    &-enter-active {
      transition: opacity 500ms, transform 500ms;
    }
    &-enter-active {
      @apply z-10;
    }
    &-leave-active {
      @apply z-0;
    }
  }
}
</style>
