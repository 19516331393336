<template>
  <section class="border-t py-10 lg:py-12">
    <div class="container">
      <h2 class="sr-only">
        Miksi ostaa Tarvikekeskuksesta?
      </h2>
      <div class="flex items-center sm:items-start gap-y-6 gap-x-10 flex-col sm:flex-row max-w-screen-md mx-auto">
        <ol class="flex-1 list-reset grid md:grid-cols-2 md:gap-2 text-base md:text-sm lg:text-base">
          <li v-for="benefit in benefits" :key="benefit.id" class="flex gap-3 items-center">
            <span class="rounded-full text-grey-500 w-12 h-12 flex-none flex items-center justify-center">
              <component :is="benefit.icon" size="28" />
            </span>
            {{ $t(benefit.label) }}
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { TruckIcon, FlagIcon, StarIcon, EuroIcon } from '~/components/veke-ui/icons'

const benefits = [
  {
    id: 'star-icon',
    icon: StarIcon,
    label: 'satisfiedCustomers'
  },
  {
    id: 'truck-icon',
    icon: TruckIcon,
    label: 'fastDeliveries'
  },
  {
    id: 'euro-icon',
    icon: EuroIcon,
    label: 'paymentFlexibility'
  },
  {
    id: 'flag-icon',
    icon: FlagIcon,
    label: 'reliableDomesticCompany'
  }
]
</script>
