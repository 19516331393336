<template>
  <div data-price :class="cssClasses">
    <template v-if="finalPrice.inclTax < price.inclTax">
      <div aria-label="Alennushinta" class="font-display font-medium [text-box:trim-both_cap_alphabetic] py-[0.5em] text-[1.4em]/none px-2 text-white relative">
        <div class="relative z-10">
          <span v-if="isStartingPrice" class="text-[.7em]">alk.</span>
          {{ eur(finalPrice.inclTax) }}
        </div><div class="absolute inset-0 -skew-x-12 -z-0 transform bg-red-800" />
      </div>
      <div aria-label="Normaalihinta" class="font-display text-[1em]/[1.5] text-muted line-through">
        {{ eur(price.inclTax) }}
      </div>
      <div v-if="showDiscountPercent && discountPercent" class="text-red-600 font-display text-[1em]/[1.5]">
        -{{ discountPercent }} %
      </div>
    </template>
    <template v-else>
      <div aria-label="Hinta" class="font-display font-medium text-[1.4em]/[1.5] text-secondary">
        <span v-if="isStartingPrice" class="text-[.7em]">alk.</span>
        {{ eur(finalPrice.inclTax) }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { eur } from '~/helpers/filters'
import type { Price } from '~/types/product'
import { usePrice } from '~/composables/usePrice'

const { getDiscountPercent } = usePrice()

const props = defineProps({
  price: {
    type: Object as () => Price,
    required: true
  },
  finalPrice: {
    type: Object as () => Price,
    required: true
  },
  isStartingPrice: {
    type: Boolean,
    default: false
  },
  showDiscountPercent: {
    type: Boolean,
    default: false
  }
})

defineOptions({
  inheritAttrs: false
})

const attrs = useAttrs()

const discountPercent = computed(() => {
  return getDiscountPercent(props.price.inclTax, props.finalPrice.inclTax)
})
const cssClasses = computed(() => {
  return twMerge('isolate inline-flex items-baseline gap-y-[.25em] gap-x-[.75em]', attrs.class as string)
})
</script>
