<template>
  <output
    role="status"
    class="toast z-10 col-start-1 row-start-1 block lg:inline-block lg:rounded shadow-xl overflow-hidden cursor-pointer"
    :class="toast.type"
    data-jest="toast"
    @click="removeAll"
  >
    <span class="container lg:mx-0 lg:px-6 min-h-16 py-3 flex justify-between lg:justify-center items-center gap-3 lg:flex-col">
      <span class="flex-1 lg:flex-none" data-jest="toast-message">
        {{ toast.message }}
      </span>
      <BaseButton
        v-if="toast.cta"
        class="text-xs"
        :to="toast.cta.to"
        look="outline-invert"
      >
        {{ toast.cta.label }}
      </BaseButton>
    </span>
    <span v-if="toast.ttl" class="block pointer-events-none h-0.5 -mt-0.5 relative" :style="'--toast-ttl:'+(toast.ttl || 0)+'ms;'">
      <span class="animate-close-timer block bg-white/50 w-0 h-full absolute left-0" />
    </span>
  </output>
</template>

<script setup lang="ts">
import BaseButton from '~/components/BaseButton.vue'
import { useToastStore } from '~/stores/toast'
import type { Toast } from '~/types/toast'

const props = defineProps<{
  toast: Toast
}>()

const toastStore = useToastStore()

const remove = () => {
  toastStore.removeToast(props.toast.id || '')
}

const removeAll = () => {
  toastStore.clearToasts()
}

onMounted(() => {
  if (props.toast.ttl && props.toast.ttl > 0) {
    setTimeout(() => {
      remove()
    }, props.toast.ttl)
  }
})
</script>

<style lang="scss" scoped>
.toast {
  @apply bg-surface-raised text-default;
  &:not(:last-child) {
    @apply z-0 lg:opacity-0 lg:invisible pointer-events-none;
  }
  &.success {
    @apply bg-pine-950 text-white;
  }
  &.error {
    @apply bg-red-800 text-white;
  }
  .animate-close-timer {
    animation-name: closeTimer;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-duration: var(--toast-ttl);
  }
}
@keyframes closeTimer {
  100% {
    width: 100%;
  }
}
</style>
