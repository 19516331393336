<template>
  <Transition :name="transition">
    <div v-if="current" class="shadow-md visible left-0 absolute inset-y-0 bg-surface-raised z-30 overflow-y-auto w-full h-full duration-300 transition-all">
      <section class="left-full top-0 w-full sticky h-16 px-4 py-2 z-30 flex gap-4 justify-between items-center text-center transition-all bg-gold-100 text-pine-950">
        <BaseButton look="blank" class="px-0 lg:hover:text-red" @click="back">
          <ArrowLeftIcon class="size-6" aria-hidden="true" /> Takaisin
        </BaseButton>
        <BaseButton look="blank" class="text-pine-950 hover:bg-gold-50 size-12 p-0" @click="close">
          <XMarkIcon class="size-6" />
        </BaseButton>
      </section>
      <nav class="grid overflow-x-hidden">
        <Transition :name="transition">
          <div :key="current.id" class="w-full min-w-0 col-start-1 col-end-2 row-start-1 row-end-2 duration-300">
            <div class="px-4 py-6">
              <h4 class="beta mb-4">
                {{ current.name }}
              </h4>
              <div>
                <ul>
                  <li>
                    <button class="w-full flex rounded hover:bg-grey-50 active:bg-grey-100 gap-3 font-medium h-12 px-2 items-center text-red-800" @click="openMenuItem({ slug: current.slug, path: current.path })">
                      <span>Näytä kaikki</span>
                    </button>
                  </li>
                  <li v-for="cat in categories" :key="cat.id">
                    <MainMenuItem v-if="cat.path" :item="cat" @click.prevent.capture="openMenuItem(cat)" />
                  </li>
                </ul>
              </div>
              <div class="py-6 text-center md:hidden">
                <BaseButton look="secondary" class="w-full" @click="back">
                  <span class="flex items-center gap-2">
                    <ArrowLeftIcon class="size-6" /><span>Takaisin</span>
                  </span>
                </BaseButton>
              </div>
            </div>
          </div>
        </Transition>
      </nav>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useUiStore } from '~/stores/ui'
import type { CategoryTreeItem } from '~/types/category'

const uiStore = useUiStore()

const props = defineProps({
  item: {
    type: Array as () => any[],
    required: true
  },
  transition: {
    type: String,
    required: false,
    default: 'fade'
  }
})

const emit = defineEmits(['menu-item-opened', 'menu-back'])

const current = computed(() => {
  return props.item[props.item.length - 1]
})
const categories = computed(() => {
  return current.value.children.filter((cat: CategoryTreeItem) => cat.isIncludedInMenu === true)
})

const close = () => {
  uiStore.closeMainMenu()
}

const openMenuItem = (category: any) => {
  emit('menu-item-opened', category)
}
const back = () => {
  emit('menu-back')
}
</script>
