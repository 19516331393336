<template>
  <div ref="searchpanel" class="search-panel md:bg-transparent md:inset-x-auto md:inset-y-auto md:static md:w-auto md:h-auto">
    <div class="relative z-2 flex-1 md:opacity-100 transition-opacity md:transition-none duration-300 delay-200">
      <form @submit.prevent="toSearchPage">
        <div class="relative">
          <input
            ref="searchinput"
            v-model="query"
            class="w-full bg-white dark:bg-grey-100 focus-visible:border-grey-700 focus:outline-none dark:focus-visible:bg-grey-50 transition-colors border border-white rounded-full pl-10 pr-3 h-11 text-default placeholder:text-muted"
            type="search"
            name="site-search"
            placeholder="Etsi"
            autocomplete="off"
            @input="event => updateSearch((event.target as HTMLInputElement).value)"
          >
          <span class="absolute start-1 inset-y-0 flex items-center justify-center px-2 pointer-events-none"><MagnifyingGlassIcon class="size-5 text-secondary" /></span>
        </div>
      </form>
    </div>

    <div v-if="suggestionsVisible" class="absolute z-50 translate-y-2 w-full left-1/2 transform -translate-x-1/2 max-w-screen-lg">
      <div ref="resultspanel" class="search-panel--results bg-surface-raised shadow-xl overflow-y-auto overscroll-contain pb-6 lg:py-4 md:border px-container md:px-4">
        <div class="grid lg:grid-cols-4 gap-6">
          <div class="order-2 lg:order-1">
            <div class="order-2 lg:order-1">
              <div v-if="suggestionsVisible">
                <div v-if="categories.length > 0" class="mb-6">
                  <div class="delta mb-2">
                    Kategoriat
                  </div>
                  <div class="flex flex-col gap-4 -mx-3 md:mx-0">
                    <ol class="list-reset grid">
                      <li v-for="item in categoryItems(categories)" :key="item.objectID">
                        <SearchItemLink :item="{ strap: item.parentCategory ? item.parentCategory + '/' : '', text: item.name, path: '/'+item.path }" @click="clearQuery" />
                      </li>
                    </ol>
                  </div>
                </div>
                <div v-else />
              </div>
            </div>

            <div class="order-2 lg:order-1">
              <div v-if="suggestionsVisible">
                <div v-if="contents.length > 0" class="mb-6">
                  <div class="delta mb-2">
                    Sivut
                  </div>
                  <div class="flex flex-col gap-4 -mx-3 md:mx-0">
                    <ol class="list-reset grid">
                      <li v-for="item in contents" :key="item.objectID">
                        <SearchItemLink :item="{ strap: item.parentName ? item.parentName + '/' : '', text: item.name, path: '/'+item.path, url: item.url }" @click="clearQuery" />
                      </li>
                    </ol>
                  </div>
                </div>
                <div v-else />
              </div>
            </div>

            <div class="order-2 lg:order-1">
              <div v-if="suggestionsVisible">
                <div v-if="blogs.length > 0">
                  <div class="delta mb-2">
                    Uutiset ja artikkelit
                  </div>
                  <div class="flex flex-col gap-4 -mx-3 md:mx-0">
                    <ol class="list-reset grid">
                      <li v-for="item in blogs" :key="item.objectID">
                        <SearchItemLink :item="{ strap: parseDate(item.date), text: item.name, path: '/uutiset-ja-artikkelit/'+item.slug }" @click="clearQuery">
                          <span v-html="item.title && !item.title.includes('script') ? item.title : 'Artikkeli'" />
                        </SearchItemLink>
                      </li>
                    </ol>
                  </div>
                </div>
                <div v-else />
              </div>
            </div>
          </div>
          <div v-if="suggestionsVisible" class="order-1 lg:order-2 lg:col-span-3">
            <div class="hidden lg:block delta mb-2">
              Tuotteet
            </div>
            <div>
              <div v-if="items.length > 0" class="flex flex-col gap-4 lg:gap-6">
                <ol class="product-search-item-list -mx-container md:mx-0 list-reset grid md:grid-cols-2">
                  <li v-for="item in items" :key="item.objectID">
                    <SearchItemProduct
                      class="h-full py-3 lg:py-4"
                      :item="item"
                      @click="() => { clearQuery(); eventHandler(item) }"
                    />
                  </li>
                </ol>
                <div class="flex flex-col lg:flex-row gap-6 justify-between">
                  <a :href="'/haku?q=' + query" class="btn btn-outline" @click.prevent="toSearchPage">
                    Näytä kaikki tuotteet
                  </a>
                  <div class="-mx-4 bg-grey-50 lg:bg-transparent p-6 lg:py-0 flex flex-col lg:flex-row gap-4 items-center">
                    <span class="text-secondary">Etsitkö varaosia?</span> <BaseButton to="/varaosahaku" look="outline">
                      Varaosahaku
                    </BaseButton>
                  </div>
                </div>
              </div>
              <div v-else class="p-2 text-sm text-muted">
                <i>Tuotteita ei löytynyt.</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash.debounce'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { useScroll, useFocus, onClickOutside } from '@vueuse/core'
import { useCategoryStore } from '~/stores/category'
import SearchItemProduct from '~/components/search/SearchItemProduct.vue'
import SearchItemLink from '~/components/search/SearchItemLink.vue'
import { attributesForProductTile } from '~/helpers/instantsearch'
import type { ProductAlgolia } from '~/types/product'
import { useTime } from '~/composables/useTime'

const categoryStore = useCategoryStore()
const query = ref('')
const router = useRouter()
const route = useRoute()
const runtimeConfig = useRuntimeConfig()

const categoryIndexName = runtimeConfig.public.ALGOLIA_CATEGORY_INDEX
const contentsIndexName = runtimeConfig.public.ALGOLIA_CONTENTS_INDEX
const blogsIndexName = runtimeConfig.public.ALGOLIA_BLOGS_INDEX

const items = ref<ProductAlgolia[]>([])
const categories = ref<any[]>([])
const contents = ref<any[]>([])
const blogs = ref<any[]>([])

const isSuggestionsOpen = ref(false)
const searchpanel = ref<HTMLElement | null>(null)
const searchinput = ref<HTMLElement | null>(null)
const resultspanel = ref<HTMLElement | null>(null)

const { focused } = useFocus(searchinput)

const { isScrolling } = useScroll(resultspanel)

watch(() => isScrolling.value, () => {
  if (isScrolling.value) {
    focused.value = false
  }
})

watch(() => route.path, () => {
  // Close suggestions when route changes
  closeSuggestions()
})

const closeSuggestions = () => {
  isSuggestionsOpen.value = false
  focused.value = false
}

onClickOutside(searchpanel, () => {
  closeSuggestions()
})

const toSearchPage = () => {
  if (query.value) {
    const queryText = query.value + ''
    if (route.name === 'haku') {
      if (route.query.q !== queryText) {
        const queryObj: { [key: string]: { query: string, sortBy: string } } = {}
        // @ts-ignore
        queryObj[runtimeConfig.public.algolia.globalIndex] = { query: queryText, sortBy: route.query[runtimeConfig.public.algolia.globalIndex]?.sortBy }
        // @ts-ignore
        router.push({ path: '/haku', query: queryObj })
      }
      if (import.meta.client) {
        window.scrollTo({ top: 0, left: 0 })
      }
    }
    else {
      const queryObj: { [key: string]: { query: string } } = {}
      queryObj[runtimeConfig.public.algolia.globalIndex] = { query: queryText }
      // @ts-ignore
      router.push({ path: '/haku', query: queryObj })
    }

    closeSuggestions()
  }
}

const clearQuery = () => {
  closeSuggestions()
  query.value = ''
}

const { result: productResult, search: searchProducts } = useAlgoliaSearch()
const { result: categoryResult, search: searchCategories } = useAlgoliaSearch(categoryIndexName)
const { result: contentResult, search: searchContents } = useAlgoliaSearch(contentsIndexName)
const { result: blogResult, search: searchBlogs } = useAlgoliaSearch(blogsIndexName)

const updateSearch = debounce(async function (val: string) {
  query.value = val
  if (val) {
    await Promise.all([
      searchProducts({ query: val, requestOptions: { filters: '', distinct: true, attributesToRetrieve: attributesForProductTile, attributesToHighlight: [], hitsPerPage: 8, ruleContexts: ['product_quick_search'] } }),
      searchCategories({ query: val, requestOptions: { filters: '', distinct: true, attributesToRetrieve: ['name', 'path', 'breadcrumb'], attributesToHighlight: [], hitsPerPage: 4, ruleContexts: ['category_quick_search'] } }),
      searchContents({ query: val, requestOptions: { filters: '', distinct: true, attributesToRetrieve: ['parentName', 'name', 'path', 'url'], attributesToHighlight: [], hitsPerPage: 3, ruleContexts: ['contents_quick_search'] } }),
      searchBlogs({ query: val, requestOptions: { filters: '', distinct: true, attributesToRetrieve: ['title', 'slug', 'date'], attributesToHighlight: [], hitsPerPage: 3, ruleContexts: ['blogs_quick_search'] } })
    ])

    items.value = productResult.value.hits as ProductAlgolia[]
    categories.value = categoryResult.value.hits as any[]
    contents.value = contentResult.value.hits as any[]
    blogs.value = blogResult.value.hits as any[]
    // send gtm event
    // const gtm = useGtm()
    // const itemIds = items.value.map(item => item.itemId.toString())
    // gtm?.trackEvent({
    //   event: 'search',
    //   search_term: val,
    //   search_results_count: itemIds.length,
    //   search_results: itemIds
    // })

    isSuggestionsOpen.value = true
  }
  else {
    isSuggestionsOpen.value = false
  }
}, 150)

const namesOfMainCategories = categoryStore.getMainCategories.map((cat: any) => cat.name)

const categoryItems = (items: any) =>
  items.map((item: { breadcrumb?: string, name: string, objectID: string, path: string }) => {
    let parentCategory = null
    if (namesOfMainCategories.includes(item.name)) {
      parentCategory = 'Tuotteet'
    }
    else if (item.breadcrumb) {
      const crumbs = item.breadcrumb?.split('>') || []
      parentCategory = crumbs.length > 1 ? crumbs.slice(-2)[0] : null
    }
    return {
      ...item,
      parentCategory
    }
  })

const parseDate = (date: string) => {
  const { formatISOString } = useTime()
  return formatISOString(date, 'd.M.yyyy')
}

const suggestionsVisible = computed(() => query.value.length && query.value.length > 1 && isSuggestionsOpen.value)

const eventHandler = (_item: any) => {
  // nuxtApp.$algoliaInsights('clickedObjectIDsAfterSearch', {
  //   userToken: store.getters['user/loggedIn'] ? 'logged_in_user' : 'guest_user',
  //   index: nuxtApp.$config.ALGOLIA_INDEX,
  //   eventName: 'product_click_after_search_bar_search',
  //   queryID: item.__queryID,
  //   objectIDs: [item.objectID],
  //   positions: [item.__position]
  // })
}
</script>

<style scoped lang="scss">
.search-panel {
  .search-panel--results {
    max-height: calc(100vh - 64px - 112px);
    @apply lg:max-h-none;

    .product-search-item-list {
      >li:nth-of-type(1n+7) {
        @apply hidden;

        @screen md {
          @apply list-item;
        }
      }
    }
  }
}
</style>
