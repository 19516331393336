// stores/ui.ts
import { defineStore } from 'pinia'
import type { UiState } from '~/types/ui'

export const useUiStore = defineStore('ui', {
  state: (): UiState => ({
    isMainMenuOpen: false,
    isMilieuImageShown: true,
    isManualImageSelection: false
  }),

  actions: {
    openMainMenu() {
      this.isMainMenuOpen = true
    },
    closeMainMenu() {
      this.isMainMenuOpen = false
    },
    toggleMainMenu() {
      this.isMainMenuOpen = !this.isMainMenuOpen
    },
    toggleMilieuImageShown() {
      this.isMilieuImageShown = !this.isMilieuImageShown
      this.isManualImageSelection = true
    },
    setMilieuImageShown(payload: boolean) {
      this.isMilieuImageShown = payload
    }
  }
})
