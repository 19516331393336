import revive_payload_client_3Ti3jHoYve from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QLLZKG7sBS from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rqcBbLRFV9 from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_445XE3b123 from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_F96HuU0zyd from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_AHPUMWm86X from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pEYhab0q9m from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KcEZlK3mQb from "/src/nuxt-app/node_modules/.pnpm/@pinia+nuxt@0.5.4_@rollup+wasm-node@4.38.0_magicast@0.3.5_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_cTvy0X7Ogi from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_UnE7gZxajq from "/src/nuxt-app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_@rollup+wasm-node@4.38.0_magicast@0.3.5_nuxt@3.13.0_@parcel+_tlzylrfm4iow6expycps5ozbai/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/src/nuxt-app/.nuxt/floating-vue.mjs";
import sentry_client_9x2m1VoTCf from "/src/nuxt-app/node_modules/.pnpm/@sentry+nuxt@8.40.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.28.0_@opentelemetry+api@1._ppe6jmijraz55cmf6jv6kyp2e4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/src/nuxt-app/.nuxt/sentry-client-config.mjs";
import plugin_4CJXLbKQmb from "/src/nuxt-app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.4_@rollup+wasm-node@4.38.0_magicast@0.3.5_t_tiuxzfco2ht6lvw4k77es45ivm/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_srSFYm4urP from "/src/nuxt-app/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@rollup+wasm-node@4.38.0_@vue+server-renderer@3.4.38_vue@3.4.38_typesc_ccjk7znp7wdcjotrd5oq2lbxhe/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_IaZGLOP097 from "/src/nuxt-app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_@rollup+wasm-node@4.38.0_magicast@0.3.5_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_HfHyfFlmX9 from "/src/nuxt-app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_@rollup+wasm-node@4.38.0_magicast@0.3.5_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import emitter_client_2TJFKX3r2I from "/src/nuxt-app/plugins/emitter.client.ts";
import firebase_client_zXNDa0wxFH from "/src/nuxt-app/plugins/firebase.client.ts";
import init_grdyAU8lhf from "/src/nuxt-app/plugins/init.ts";
export default [
  revive_payload_client_3Ti3jHoYve,
  unhead_QLLZKG7sBS,
  router_rqcBbLRFV9,
  payload_client_445XE3b123,
  navigation_repaint_client_F96HuU0zyd,
  check_outdated_build_client_AHPUMWm86X,
  chunk_reload_client_pEYhab0q9m,
  plugin_vue3_KcEZlK3mQb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_cTvy0X7Ogi,
  plugin_UnE7gZxajq,
  floating_vue_EIcJ7xiw0h,
  sentry_client_9x2m1VoTCf,
  sentry_client_config_o34nk2sJbg,
  plugin_4CJXLbKQmb,
  plugin_srSFYm4urP,
  switch_locale_path_ssr_IaZGLOP097,
  i18n_HfHyfFlmX9,
  emitter_client_2TJFKX3r2I,
  firebase_client_zXNDa0wxFH,
  init_grdyAU8lhf
]