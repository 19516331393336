import { default as _91_46_46_46slug_93lKukO4N5GGMeta } from "/src/nuxt-app/pages/category/[...slug].vue?macro=true";
import { default as indexlN49LoEhCEMeta } from "/src/nuxt-app/pages/category/index.vue?macro=true";
import { default as _91_46_46_46slug_93akHkV0oGjPMeta } from "/src/nuxt-app/pages/cms/[...slug].vue?macro=true";
import { default as hakuN2kVWS1ZaGMeta } from "/src/nuxt-app/pages/haku.vue?macro=true";
import { default as indexwkKKMTZsCzMeta } from "/src/nuxt-app/pages/index.vue?macro=true";
import { default as jaettu_45ostoskoriodR4yq85ggMeta } from "/src/nuxt-app/pages/jaettu-ostoskori.vue?macro=true";
import { default as kampanjatr0xWpijUHRMeta } from "/src/nuxt-app/pages/kampanjat.vue?macro=true";
import { default as kassa_46clienta3auhRHawbMeta } from "/src/nuxt-app/pages/kassa.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as kiitosUNZTduWwP3Meta } from "/src/nuxt-app/pages/kiitos.vue?macro=true";
import { default as kirjaudu_46clientuOLPxKTPm3Meta } from "/src/nuxt-app/pages/kirjaudu.client.vue?macro=true";
import { default as luo_45tili_46client9ixDLWUb80Meta } from "/src/nuxt-app/pages/luo-tili.client.vue?macro=true";
import { default as indexmGY4PiOETJMeta } from "/src/nuxt-app/pages/oma-tili/index.vue?macro=true";
import { default as markkinointiluvatPfAJTOgHSkMeta } from "/src/nuxt-app/pages/oma-tili/markkinointiluvat.vue?macro=true";
import { default as omat_45tiedot7vwppHVzRlMeta } from "/src/nuxt-app/pages/oma-tili/omat-tiedot.vue?macro=true";
import { default as _91_46_46_46slug_939PrWenbZ59Meta } from "/src/nuxt-app/pages/oma-tili/tilaukset/[...slug].vue?macro=true";
import { default as indexBx6DWEC5bGMeta } from "/src/nuxt-app/pages/oma-tili/tilaukset/index.vue?macro=true";
import { default as _91id_93WN1JQhHXSPMeta } from "/src/nuxt-app/pages/oma-tili/toivelistat/[id].vue?macro=true";
import { default as indexBxx0rBzmBkMeta } from "/src/nuxt-app/pages/oma-tili/toivelistat/index.vue?macro=true";
import { default as toivelistatbgiPPohd7dMeta } from "/src/nuxt-app/pages/oma-tili/toivelistat.vue?macro=true";
import { default as yhteenvetopEOHVsFVjIMeta } from "/src/nuxt-app/pages/oma-tili/yhteenveto.vue?macro=true";
import { default as oma_45tiliz96eG4kwpNMeta } from "/src/nuxt-app/pages/oma-tili.vue?macro=true";
import { default as ostoskorirC32QwoUR4Meta } from "/src/nuxt-app/pages/ostoskori.vue?macro=true";
import { default as _91slug_93eQTSCIKTuqMeta } from "/src/nuxt-app/pages/product/[slug].vue?macro=true";
import { default as styleguidexlLQn1Tj6FMeta } from "/src/nuxt-app/pages/styleguide.vue?macro=true";
import { default as tarjouskoriEq8Xn8VtlpMeta } from "/src/nuxt-app/pages/tarjouskori.vue?macro=true";
import { default as unohditko_45salasanasi1QjSzFwHgQMeta } from "/src/nuxt-app/pages/unohditko-salasanasi.vue?macro=true";
import { default as _91_46_46_46slug_93JccMEkLoArMeta } from "/src/nuxt-app/pages/uutiset-ja-artikkelit/[...slug].vue?macro=true";
import { default as indexEblgRKaGMLMeta } from "/src/nuxt-app/pages/uutiset-ja-artikkelit/index.vue?macro=true";
import { default as vahvista_45sahkopostiosoitteesiF9xIhcgQp3Meta } from "/src/nuxt-app/pages/vahvista-sahkopostiosoitteesi.vue?macro=true";
import { default as vaihtolaitteetlvO9otLqDaMeta } from "/src/nuxt-app/pages/vaihtolaitteet.vue?macro=true";
import { default as varaosahakuw5K0nzVZDSMeta } from "/src/nuxt-app/pages/varaosahaku.vue?macro=true";
export default [
  {
    name: "category-slug___fi",
    path: "/category/:slug(.*)*",
    component: () => import("/src/nuxt-app/pages/category/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "category-slug___en",
    path: "/en/category/:slug(.*)*",
    component: () => import("/src/nuxt-app/pages/category/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "category___fi",
    path: "/category",
    component: () => import("/src/nuxt-app/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "category___en",
    path: "/en/category",
    component: () => import("/src/nuxt-app/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: "cms-slug___fi",
    path: "/cms/:slug(.*)*",
    component: () => import("/src/nuxt-app/pages/cms/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cms-slug___en",
    path: "/en/cms/:slug(.*)*",
    component: () => import("/src/nuxt-app/pages/cms/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "haku___fi",
    path: "/haku",
    component: () => import("/src/nuxt-app/pages/haku.vue").then(m => m.default || m)
  },
  {
    name: "haku___en",
    path: "/en/haku",
    component: () => import("/src/nuxt-app/pages/haku.vue").then(m => m.default || m)
  },
  {
    name: "index___fi",
    path: "/",
    component: () => import("/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jaettu-ostoskori___fi",
    path: "/jaettu-ostoskori",
    component: () => import("/src/nuxt-app/pages/jaettu-ostoskori.vue").then(m => m.default || m)
  },
  {
    name: "jaettu-ostoskori___en",
    path: "/en/jaettu-ostoskori",
    component: () => import("/src/nuxt-app/pages/jaettu-ostoskori.vue").then(m => m.default || m)
  },
  {
    name: "kampanjat___fi",
    path: "/kampanjat",
    component: () => import("/src/nuxt-app/pages/kampanjat.vue").then(m => m.default || m)
  },
  {
    name: "kampanjat___en",
    path: "/en/kampanjat",
    component: () => import("/src/nuxt-app/pages/kampanjat.vue").then(m => m.default || m)
  },
  {
    name: "kassa___fi",
    path: "/kassa",
    meta: kassa_46clienta3auhRHawbMeta || {},
    component: () => createClientPage(() => import("/src/nuxt-app/pages/kassa.client.vue").then(m => m.default || m))
  },
  {
    name: "kassa___en",
    path: "/en/kassa",
    meta: kassa_46clienta3auhRHawbMeta || {},
    component: () => createClientPage(() => import("/src/nuxt-app/pages/kassa.client.vue").then(m => m.default || m))
  },
  {
    name: "kiitos___fi",
    path: "/kiitos",
    meta: kiitosUNZTduWwP3Meta || {},
    component: () => import("/src/nuxt-app/pages/kiitos.vue").then(m => m.default || m)
  },
  {
    name: "kiitos___en",
    path: "/en/kiitos",
    meta: kiitosUNZTduWwP3Meta || {},
    component: () => import("/src/nuxt-app/pages/kiitos.vue").then(m => m.default || m)
  },
  {
    name: "kirjaudu___fi",
    path: "/kirjaudu",
    component: () => createClientPage(() => import("/src/nuxt-app/pages/kirjaudu.client.vue").then(m => m.default || m))
  },
  {
    name: "kirjaudu___en",
    path: "/en/kirjaudu",
    component: () => createClientPage(() => import("/src/nuxt-app/pages/kirjaudu.client.vue").then(m => m.default || m))
  },
  {
    name: "luo-tili___fi",
    path: "/luo-tili",
    component: () => createClientPage(() => import("/src/nuxt-app/pages/luo-tili.client.vue").then(m => m.default || m))
  },
  {
    name: "luo-tili___en",
    path: "/en/luo-tili",
    component: () => createClientPage(() => import("/src/nuxt-app/pages/luo-tili.client.vue").then(m => m.default || m))
  },
  {
    name: oma_45tiliz96eG4kwpNMeta?.name,
    path: "/oma-tili",
    component: () => import("/src/nuxt-app/pages/oma-tili.vue").then(m => m.default || m),
    children: [
  {
    name: "oma-tili___fi",
    path: "",
    meta: indexmGY4PiOETJMeta || {},
    component: () => import("/src/nuxt-app/pages/oma-tili/index.vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-markkinointiluvat___fi",
    path: "markkinointiluvat",
    component: () => import("/src/nuxt-app/pages/oma-tili/markkinointiluvat.vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-omat-tiedot___fi",
    path: "omat-tiedot",
    component: () => import("/src/nuxt-app/pages/oma-tili/omat-tiedot.vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-tilaukset-slug___fi",
    path: "tilaukset/:slug(.*)*",
    component: () => import("/src/nuxt-app/pages/oma-tili/tilaukset/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-tilaukset___fi",
    path: "tilaukset",
    component: () => import("/src/nuxt-app/pages/oma-tili/tilaukset/index.vue").then(m => m.default || m)
  },
  {
    name: toivelistatbgiPPohd7dMeta?.name,
    path: "toivelistat",
    component: () => import("/src/nuxt-app/pages/oma-tili/toivelistat.vue").then(m => m.default || m),
    children: [
  {
    name: "oma-tili-toivelistat-id___fi",
    path: ":id()",
    component: () => import("/src/nuxt-app/pages/oma-tili/toivelistat/[id].vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-toivelistat___fi",
    path: "",
    component: () => import("/src/nuxt-app/pages/oma-tili/toivelistat/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "oma-tili-yhteenveto___fi",
    path: "yhteenveto",
    meta: yhteenvetopEOHVsFVjIMeta || {},
    component: () => import("/src/nuxt-app/pages/oma-tili/yhteenveto.vue").then(m => m.default || m)
  }
]
  },
  {
    name: oma_45tiliz96eG4kwpNMeta?.name,
    path: "/en/oma-tili",
    component: () => import("/src/nuxt-app/pages/oma-tili.vue").then(m => m.default || m),
    children: [
  {
    name: "oma-tili___en",
    path: "",
    meta: indexmGY4PiOETJMeta || {},
    component: () => import("/src/nuxt-app/pages/oma-tili/index.vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-markkinointiluvat___en",
    path: "markkinointiluvat",
    component: () => import("/src/nuxt-app/pages/oma-tili/markkinointiluvat.vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-omat-tiedot___en",
    path: "omat-tiedot",
    component: () => import("/src/nuxt-app/pages/oma-tili/omat-tiedot.vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-tilaukset-slug___en",
    path: "tilaukset/:slug(.*)*",
    component: () => import("/src/nuxt-app/pages/oma-tili/tilaukset/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-tilaukset___en",
    path: "tilaukset",
    component: () => import("/src/nuxt-app/pages/oma-tili/tilaukset/index.vue").then(m => m.default || m)
  },
  {
    name: toivelistatbgiPPohd7dMeta?.name,
    path: "toivelistat",
    component: () => import("/src/nuxt-app/pages/oma-tili/toivelistat.vue").then(m => m.default || m),
    children: [
  {
    name: "oma-tili-toivelistat-id___en",
    path: ":id()",
    component: () => import("/src/nuxt-app/pages/oma-tili/toivelistat/[id].vue").then(m => m.default || m)
  },
  {
    name: "oma-tili-toivelistat___en",
    path: "",
    component: () => import("/src/nuxt-app/pages/oma-tili/toivelistat/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "oma-tili-yhteenveto___en",
    path: "yhteenveto",
    meta: yhteenvetopEOHVsFVjIMeta || {},
    component: () => import("/src/nuxt-app/pages/oma-tili/yhteenveto.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "ostoskori___fi",
    path: "/ostoskori",
    component: () => import("/src/nuxt-app/pages/ostoskori.vue").then(m => m.default || m)
  },
  {
    name: "ostoskori___en",
    path: "/en/ostoskori",
    component: () => import("/src/nuxt-app/pages/ostoskori.vue").then(m => m.default || m)
  },
  {
    name: "product-slug___fi",
    path: "/product/:slug()",
    component: () => import("/src/nuxt-app/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-slug___en",
    path: "/en/product/:slug()",
    component: () => import("/src/nuxt-app/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: "styleguide___fi",
    path: "/styleguide",
    component: () => import("/src/nuxt-app/pages/styleguide.vue").then(m => m.default || m)
  },
  {
    name: "styleguide___en",
    path: "/en/styleguide",
    component: () => import("/src/nuxt-app/pages/styleguide.vue").then(m => m.default || m)
  },
  {
    name: "tarjouskori___fi",
    path: "/tarjouskori",
    component: () => import("/src/nuxt-app/pages/tarjouskori.vue").then(m => m.default || m)
  },
  {
    name: "tarjouskori___en",
    path: "/en/tarjouskori",
    component: () => import("/src/nuxt-app/pages/tarjouskori.vue").then(m => m.default || m)
  },
  {
    name: "unohditko-salasanasi___fi",
    path: "/unohditko-salasanasi",
    component: () => import("/src/nuxt-app/pages/unohditko-salasanasi.vue").then(m => m.default || m)
  },
  {
    name: "unohditko-salasanasi___en",
    path: "/en/unohditko-salasanasi",
    component: () => import("/src/nuxt-app/pages/unohditko-salasanasi.vue").then(m => m.default || m)
  },
  {
    name: "uutiset-ja-artikkelit-slug___fi",
    path: "/uutiset-ja-artikkelit/:slug(.*)*",
    component: () => import("/src/nuxt-app/pages/uutiset-ja-artikkelit/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "uutiset-ja-artikkelit-slug___en",
    path: "/en/uutiset-ja-artikkelit/:slug(.*)*",
    component: () => import("/src/nuxt-app/pages/uutiset-ja-artikkelit/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "uutiset-ja-artikkelit___fi",
    path: "/uutiset-ja-artikkelit",
    component: () => import("/src/nuxt-app/pages/uutiset-ja-artikkelit/index.vue").then(m => m.default || m)
  },
  {
    name: "uutiset-ja-artikkelit___en",
    path: "/en/uutiset-ja-artikkelit",
    component: () => import("/src/nuxt-app/pages/uutiset-ja-artikkelit/index.vue").then(m => m.default || m)
  },
  {
    name: "vahvista-sahkopostiosoitteesi___fi",
    path: "/vahvista-sahkopostiosoitteesi",
    component: () => import("/src/nuxt-app/pages/vahvista-sahkopostiosoitteesi.vue").then(m => m.default || m)
  },
  {
    name: "vahvista-sahkopostiosoitteesi___en",
    path: "/en/vahvista-sahkopostiosoitteesi",
    component: () => import("/src/nuxt-app/pages/vahvista-sahkopostiosoitteesi.vue").then(m => m.default || m)
  },
  {
    name: "vaihtolaitteet___fi",
    path: "/vaihtolaitteet",
    component: () => import("/src/nuxt-app/pages/vaihtolaitteet.vue").then(m => m.default || m)
  },
  {
    name: "vaihtolaitteet___en",
    path: "/en/vaihtolaitteet",
    component: () => import("/src/nuxt-app/pages/vaihtolaitteet.vue").then(m => m.default || m)
  },
  {
    name: "varaosahaku___fi",
    path: "/varaosahaku",
    component: () => import("/src/nuxt-app/pages/varaosahaku.vue").then(m => m.default || m)
  },
  {
    name: "varaosahaku___en",
    path: "/en/varaosahaku",
    component: () => import("/src/nuxt-app/pages/varaosahaku.vue").then(m => m.default || m)
  }
]