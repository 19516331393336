<template>
  <div class="group/site">
    <SkipToContent />
    <SiteHeader />
    <main id="main-content" class="min-h-[75vh]">
      <div class="py-10">
        <div class="container">
          <div class="mx-auto isolate relative h-96 max-w-96 rounded-full flex flex-col items-center justify-center">
            <div class="absolute inset-0 -skew-y-3 -z-0 transform bg-grey-50" />
            <div class="z-10 text-lg text-center text-secondary flex flex-col items-center gap-4">
              <span class="text-[100px] font-display leading-none text-grey-500">{{ props.error?.statusCode }}</span>
              <h1 v-if="props.error?.statusCode == 404">
                Sivua ei löytynyt
              </h1>
              <h1 v-else-if="props.error?.statusCode == 500">
                Jotain meni pieleen
              </h1>
              <BaseButton @click="handleError">
                Siirry etusivulle
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </main>
    <BenefitBar />
    <SiteFooter />
    <ToastNotifications />
    <MainMenu />
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

console.log(props.error)

const handleError = () => clearError({ redirect: '/' })
</script>
