import { setDoc, doc, updateDoc, deleteDoc, collection, addDoc } from 'firebase/firestore'
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  verifyBeforeUpdateEmail as verifyBeforeUpdateEmailHandler,
  updateEmail as updateEmailHandler,
  updatePassword
} from 'firebase/auth'
import type { HttpsCallable, HttpsCallableResult } from 'firebase/functions'
import { httpsCallable } from 'firebase/functions'
import type { MarketingPermissions, UserAddress, UserDetails, UserDetailsBase, UserOrder, Wishlist } from '~/types/user'
// import type { WishlistItem } from '~/types/item'
export const useFirebase = () => {
  const { $firestore, $auth, $functions } = useNuxtApp()
  const createUserDocument = async (uid: string, userDetails: UserDetailsBase): Promise<any> => {
    await setDoc(doc($firestore, 'users', uid), userDetails).then(response => console.log(response)).catch(error => console.error(error))
  }

  const updateUserDocument = async (userDetails: UserDetailsBase): Promise<boolean> => {
    const docRef = doc($firestore, 'users', $auth.currentUser.uid)
    return await updateDoc(docRef, { ...userDetails })
      .then(() => true)
      .catch((err: any) => {
        console.error('User document update failed', err)
        return false
      })
  }

  const getMarketingPermissionsStatusHandler = async (): Promise<MarketingPermissions | undefined> => {
    if ($auth.currentUser) {
      const getMarketingPermissions: HttpsCallable<unknown, MarketingPermissions | null > = httpsCallable($functions, 'tkapi-customer-getMarketingPermissionStatus')
      try {
        const response: HttpsCallableResult<MarketingPermissions | null> = await getMarketingPermissions()
        if (response.data) {
          return response.data
        }
      }
      catch (e) {
        console.error(e)
        throw new Error('Could not get marketing permissions.')
      }
    }
    else {
      return undefined
    }
  }

  const getUserDetailsWithSubcollections = async (): Promise<UserDetails | null> => {
    if ($auth.currentUser) {
      const getUser = httpsCallable($functions, 'tkapi-customer-getUser')
      return await getUser()
        .then((response: any) => {
          return response.data.response
        })
        .catch((err: any) => {
          // Sentry.captureException(err)
          console.error(err)
          return null
        })
    }
    return null
  }

  const updateUserMarketingPermissions = async (marketingPermissions: MarketingPermissions) => {
    const docRef = doc($firestore, 'users', $auth.currentUser.uid)
    return await updateDoc(docRef, { marketingPermissions: { ...marketingPermissions } })
      .then(() => true)
      .catch((err: any) => {
        console.error(err)
        return false
      })
  }

  const getUserOrders = (): Promise<UserOrder[]> => {
    if ($auth.currentUser) {
      const getOrders = httpsCallable($functions, 'tkapi-customer-getOrders')
      return getOrders()
        .then((response: any) => {
          return response.data.orders
        })
        .catch((err: any) => {
          // Sentry.captureException(err)
          console.error(err)
          return []
        })
    }
    return new Promise((resolve) => {
      resolve([])
    })
  }

  const addWishlist = async (name?: string): Promise<Wishlist | null> => {
    console.log('add wishlist ', name)
    if (!$auth.currentUser?.uid) {
      console.error('User not found!')
      return Promise.reject(null)
    }
    const createdAt = new Date()
    const docRef = doc($firestore, 'users', $auth.currentUser.uid)
    const wishlistCollection = collection(docRef, 'wishlists')

    const wishlist: Wishlist = {
      name: name || 'Toivelista',
      items: [],
      createdAt: createdAt.toISOString(),
      updatedAt: createdAt.toISOString()
    }
    return addDoc(wishlistCollection, wishlist)
      .then((response) => {
        return Promise.resolve({ ...wishlist, id: response.id })
      })
      .catch((err: any) => {
        console.error(err)
        return Promise.reject(null)
      })
  }

  const updateWishlist = async (wishlist: Wishlist): Promise<void> => {
    const croppedWishlist = { ...wishlist }
    const updatedAt = new Date()
    croppedWishlist.updatedAt = updatedAt.toISOString()
    const docRef = doc($firestore, 'users', $auth.currentUser.uid, 'wishlists', wishlist.id!)

    return await updateDoc(docRef, croppedWishlist)
      .catch((error: any) => {
        console.error(error)
        console.error(`Updating wishlist '${croppedWishlist.name}' failed!`)
        return Promise.reject()
      })
  }

  const deleteWishlist = async (wishlistId: string): Promise<void> => {
    const docRef = doc($firestore, 'users', $auth.currentUser?.uid, 'wishlists', wishlistId)
    return await deleteDoc(docRef)
      .catch(() => {
        console.error(`Deleting wishlist with ID '${wishlistId}' failed!`)
        return Promise.reject()
      })
  }

  const addAddress = async (address: UserAddress): Promise<any> => {
    const docRef = doc($firestore, 'users', $auth.currentUser.uid)
    const addressCollection = collection(docRef, 'addresses')
    return await addDoc(addressCollection, address)
      .catch((err: any) => {
        console.error('addAddress', err)
        return null
      })
  }

  const updateAddress = async (address: UserAddress): Promise<boolean> => {
    const docRef = doc($firestore, 'users', $auth.currentUser?.uid, 'addresses', address.id!)
    return await updateDoc(docRef, { ...address })
      .then(() => true)
      .catch((err: any) => {
        console.error('updateAddress', err)
        return false
      })
  }

  const verifyBeforeUpdateEmail = async (email: string): Promise<boolean> => {
    if ($auth.currentUser) {
      console.log('new email:', email)
      return await verifyBeforeUpdateEmailHandler($auth.currentUser, email)
        .then(() => {
          // Update email to store and on the Firestore-document
          // const store = useStore()
          // const { updateUserDocument } = useFirestore($fire)
          // const details = store.getters[UserGetters.details]
          // details.email = email
          // store.dispatch(UserActions.setUserDetails, details)
          // await updateUserDocument(details)
          return true
        })
        .catch((err: any) => {
          console.error('updateAuthEmail', err)
          return false
        })
    }
    else {
      return false
    }
  }

  const reauthenticateUser = async (psw: string): Promise<boolean> => {
    if (!$auth.currentUser || $auth.currentUser?.email === null) {
      $auth.signOut()
      return Promise.resolve(false)
    }

    // https://github.com/nuxt-community/firebase-module/issues/20
    const creds = EmailAuthProvider.credential(
      $auth.currentUser.email,
      psw
    )
    return await reauthenticateWithCredential($auth.currentUser, creds)
      .then(() => true)
      .catch((err: any) => {
        console.error('reauthenticateWithCredential', err)
        return false
      })
  }

  const updateEmail = async (newEmail: string) => {
    return updateEmailHandler($auth.currentUser, newEmail)
      .then((response) => {
        console.log(response)
        // Email updated!
        console.log('Email updated successfully')
        return true
      })
      .catch((error) => {
        // An error occurred
        console.error('Error updating email:', error)
        return false
      })
  }

  const updateAuthPassword = async (form: any): Promise<boolean> => {
    const updatePasswordHandler = async (psw: string, newPsw: string) => {
      return await reauthenticateUser(psw)
        .then(async (success) => {
          if (success) {
            return updatePassword($auth.currentUser, newPsw)
              .then(() => {
                return true
              })
              .catch(() => {
                return false
              })
          }
          else {
            return false
          }
        })
    }
    return updatePasswordHandler(form.oldPassword, form.password)
  }

  const removeAddress = async (id: string): Promise<boolean> => {
    const docRef = doc($firestore, 'users', $auth.currentUser?.uid, 'addresses', id)
    return await deleteDoc(docRef)
      .then(() => true)
      .catch((err: any) => {
        console.error('removeAddress', err)
        return false
      })
  }

  return {
    createUserDocument,
    getUserDetailsWithSubcollections,
    updateUserDocument,
    updateUserMarketingPermissions,
    getUserOrders,
    addWishlist,
    updateWishlist,
    deleteWishlist,
    updateAddress,
    removeAddress,
    addAddress,
    getMarketingPermissionsStatusHandler,
    verifyBeforeUpdateEmail,
    reauthenticateUser,
    updateEmail,
    updateAuthPassword
  }
}
