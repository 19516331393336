import { defineStore } from 'pinia'
import deliveryMethods from '../config/delivery-methods.json'
import { useProduct } from '~/composables/useProduct'
import { getVariationImage, formatItemVersion } from '~/helpers/product'
import { useUserStore } from '~/stores/user'
import { useToastStore } from '~/stores/toast'
import type { CartItem, CartItemPrice } from '~/types/item'
import type { ProductAlgolia, StockObject } from '~/types/product'
import type { CampaignCodeQuery, Cart as CartState, CartValidationResult, DiscountedCartItem, PriceTier } from '~/types/cart'
import type { DeliveryMethod } from '~/types/checkout'
import type { SparePart } from '~/types/part'
// import { recalculateCustomizationPrice, getProductId } from '~/helpers/customization'

// const { convertCartItemsToMatomoItems } = useProduct()

const updateItemIsCartRuleItem = (state: CartState, payload: { sku: string, isCartRuleItem: boolean }) => {
  console.log('Updating isCartRuleItem-prop for SKU:', payload.sku, 'to:', payload.isCartRuleItem)
  // Find all state.items-array indices for the given SKU (multiple omaKoko-products require this)
  const indices = state.items.reduce((acc: number[], item, index) => {
    if (item.sku === payload.sku) {
      acc.push(index)
    }
    return acc
  }, [])
  // Update the exact object in given index with new isCartRuleItem-prop value
  indices.forEach(i => state.items[i].isCartRuleItem = payload.isCartRuleItem)
}
const getDeliveryMethodsForProduct = (product: ProductAlgolia) => (deliveryMethods as DeliveryMethod[]).filter(dm => product.shippingCosts.includes(dm.id))

const isCartRuleItem = async (state: CartState, sku: string) => {
  console.log('Checking if cart rule item...', sku)
  if (!state.cartRule) return false
  const filters = state.cartRule.productSearchFilters ? state.cartRule.productSearchFilters + ' AND' : ''
  if (!filters) return true

  const { data } = await useAsyncAlgoliaSearch({ key: 'cartrule_items', query: '', requestOptions: { filters: `${filters} sku:'${sku}'`, attributesToRetrieve: ['sku'], attributesToHighlight: [] } })
  return data.value?.hits.length > 0
}

export const useCartStore = defineStore('cart', {
  state: (): CartState => ({
    items: [],
    authCartMerge: false,
    authCartItems: undefined,
    createdAt: '',
    loading: false,
    giftCard: null,
    campaign: null,
    validationResult: null,
    cartRule: null,
    itemsEdited: false
  }),
  getters: {
    // TODO: customization logic to be implemented
    getCartItemBySku: state => (sku: string /* , customization?: CustomizationWithIdentifier */): CartItem | undefined => state.items.find((item: CartItem) => item.id === sku /* (customization?.identifier || sku) */),
    totalInclTax: state => state.items.reduce((sum: number, item: CartItem) => sum + item.price.finalPrice.inclTax * item.amount, 0),
    totalExclTax: state => state.items.reduce((sum: number, item: CartItem) => sum + item.price.finalPrice.exclTax * item.amount, 0),
    totalItemAmount: state => state.items.reduce((sum: number, item: CartItem) => sum + item.amount, 0),
    getDiscountedItemByName: state => (name: string): DiscountedCartItem | undefined => state.campaign?.discountedCartItems?.find(item => item.name === name),
    campaignDiscountAmount: (state): number => state.campaign?.discountSum || 0,
    isCartRuleAppliable: (state): boolean => state.cartRule?.isLoginRequired ? useUserStore().loggedIn : true,
    discountedCartRuleItemValue: (state): number => state.items.filter(item => item.isCartRuleItem).reduce((total: number, item: CartItem) => total + item.price.finalPrice.inclTax * item.amount, 0),
    discountedCartRuleItemAmount: (state): number => state.items.filter(item => item.isCartRuleItem).reduce((total: number, item: CartItem) => total + item.amount, 0),
    currentCartRuleTierDiscount(state: CartState) {
      return (type: 'cart_value' | 'item_amount'): PriceTier | undefined => {
        const comparator = type === 'cart_value' ? this.discountedCartRuleItemValue : this.discountedCartRuleItemAmount
        // Sort by price DESC
        return state.cartRule?.priceTiers.slice().sort((a, b) => b.from - a.from).find(o => o.from <= comparator)
      }
    },
    nextCartRuleTierDiscount(state: CartState) {
      return (type: 'cart_value' | 'item_amount'): PriceTier | undefined => {
        const comparator = type === 'cart_value' ? this.discountedCartRuleItemValue : this.discountedCartRuleItemAmount
        // Sort by price ASC
        return state.cartRule?.priceTiers.slice().sort((a, b) => a.from - b.from).find(o => o.from > comparator)
      }
    },
    isCartPriceRuleAvailableForSku: state => async (sku: string): Promise<boolean> => await isCartRuleItem(state, sku)
    // Add other getters here...
  },
  actions: {
    async addSparePartToCart(sparePart: SparePart) {
      const { getWithOutTaxSum } = usePrice()

      const vatRate = 25.5

      // Brands
      const brands: { [K in SparePart['brand']]: { name: string, supplierCode: string } } = {
        HOM: { name: 'Honda', supplierCode: '88' },
        HUS: { name: 'Husqvarna', supplierCode: '301' },
        YAM: { name: 'Yamaha', supplierCode: '22251' },
        KTM: { name: 'KTM', supplierCode: '78' },
        POL: { name: 'Polaris', supplierCode: '1044' },
        ARC: { name: 'Arctic Cat', supplierCode: '61' },
        SUZ: { name: 'Suzuki', supplierCode: '1043' }
      }

      const brand = brands[sparePart.brand]

      // @TODO: Handle missing brand - notify user
      if (!brand) {
        console.log('No brand!')
        return
      }

      // Cart item for spare parts
      const cartItem: CartItem = {
        id: sparePart.sku,
        version: formatItemVersion(useRuntimeConfig().public.ITEM_VERSION_CARTITEM as string),
        baseType: 'cartItem',
        sku: sparePart.sku + '@' + brand.supplierCode,
        link: sparePart.url,
        title: `Varaosa ${brand.name} ${sparePart.sku}`,
        variationName: `Varaosa ${brand.name} ${sparePart.sku}`,
        amount: sparePart.qty,
        image: '/images/icons/spare-part.png',
        slug: '',
        price: {
          price: { inclTax: sparePart.price, exclTax: getWithOutTaxSum(sparePart.price, vatRate) },
          finalPrice: { inclTax: sparePart.price, exclTax: getWithOutTaxSum(sparePart.price, vatRate) }
        },
        vatRate: 25.5,
        shipping: (deliveryMethods as DeliveryMethod[]),
        variationOptions: [
          { label: 'SKU', value: sparePart.sku },
          { label: 'Valmistaja', value: brand.name }
        ],
        stock: {
          qty: 9999,
          nextDeliveryDate: '',
          stockStatus: 'inStock',
          storeAvailability: {}
        },
        parentSku: sparePart.sku + '@' + brand.supplierCode,
        brands: { value: sparePart.brand, label: brand.name },
        categories: '',
        isCartRuleItem: false,
        sparePart: true
      }
      this.addCartItem(cartItem)
    },
    async addToCart(product: ProductAlgolia, options: { amount?: number, notifyUser?: boolean, referrer?: string, pushGtm?: boolean } = {}) {
      // Options
      const _options = { amount: 1, notifyUser: true, referrer: undefined, pushGtm: true, ...options }
      // update created at
      this.createdAt = new Date().toISOString()

      const deliveryMethodsForProduct = getDeliveryMethodsForProduct(product)
      const finalPrice = product.finalPrice.inclTax
      const price = product.price.exclTax
      const { getCategoryPath, getAvailableAmount } = useProduct()
      const cartItem: CartItem = {
        id: product.sku, // getProductId(product),
        version: formatItemVersion(useRuntimeConfig().public.ITEM_VERSION_CARTITEM as string),
        baseType: 'cartItem',
        sku: product.sku,
        title: product.name.default,
        variationName: product.name.variation,
        amount: getAvailableAmount(product, _options.amount),
        image: getVariationImage(product, 'thumbnail'),
        slug: product.slug,
        price: {
          price: product.price,
          finalPrice: { inclTax: product.finalPrice.inclTax, exclTax: product.finalPrice.exclTax },
          salePercent: price !== finalPrice ? (1 - (finalPrice / price)) * 100 : 0,
          priceRuleId: product.finalPrice.ruleId
        },
        vatRate: product.vatRate,
        shipping: deliveryMethodsForProduct,
        variationOptions: product.selectableVariationAttributes,
        productLine: product.productLine,
        stock: product.stock,
        erpinfo: product.erpinfo,
        parentSku: product.parentSku,
        brands: product.marketing?.productManufacturer || '',
        categories: getCategoryPath(product.categories).map(cat => cat.value).join(' / '),
        reviews: product.yotpo
      }

      // if (product.customization) {
      //   cartItem.customization = { identifier: product.customization.identifier, properties: product.customization.properties }
      //   cartItem.price.original = product.customization.price
      //   cartItem.price.final = product.customization.finalPrice
      // }

      cartItem.isCartRuleItem = await isCartRuleItem(this.$state, cartItem.sku)

      this.addCartItem(cartItem)

      if (_options.notifyUser) {
        // Dispatch toast action
        useToastStore().addToast({
          message: (cartItem.amount > 1 ? cartItem.amount + ' kpl' : 'Tuote') + ' lisätty ostoskoriin!',
          type: 'success',
          ttl: 5000,
          cta: { label: 'Siirry ostoskoriin', to: '/ostoskori' }
        })
      }

      // // Matomo - Cart Update
      // this.app.$matomo.trackEcommerceCartUpdate(convertCartItemsToMatomoItems(state.items))

      // if (_options.pushGtm) {
      //   this.app.$gtm.push({ ecommerce: null, cart_contents: null })
      //   this.app.$gtm.push({
      //     event: 'addToCart',
      //     addToCartLocation: _options.referrer,
      //     ecommerce: {
      //       currencyCode: 'EUR',
      //       add: { products: [convertCartItemToGtmProduct(cartItem)] }
      //     },
      //     cart_contents: state.value.items.map(item => convertCartItemToGtmProduct(item))
      //   })
      // }
    },
    /**
     * Add a cart item to the shopping cart.
     * If the item already exists in the cart, increase the amount.
     * @param cartItem
     */
    async addCartItem(cartItem: CartItem) {
      const existingProduct = this.items.find(item => item.id === cartItem.id)
      if (existingProduct) {
        existingProduct.amount += cartItem.amount
        existingProduct.stock = cartItem.stock
      }
      else {
        this.items.push(cartItem)
      }
    },
    async validateCart(): Promise<boolean> {
      const { $auth } = useNuxtApp()
      // Reset validation errors
      this.validationResult = null

      console.log('Validating cart...', this.items)
      return await $fetch(useRuntimeConfig().public.FUNCTIONS_API_URL + '/tkapi-paymentGateway-validateCartV2', {
        method: 'POST',
        body: { items: this.items, campaign: this.campaign },
        headers: { Authorization: await $auth.currentUser?.getIdToken() }
      })
        .then((response: any) => {
          const cartValidationResult: CartValidationResult = response
          if (cartValidationResult.success === true) {
            return true
          }
          else {
            console.error('Cart validation error(s) were found:', cartValidationResult)
            this.validationResult = cartValidationResult
            if (!cartValidationResult.validations.campaignCodeValidationResult?.success) {
              this.campaign = null
              // After clearing current invalid campaign code, check if there's an active tier price available
              this.fetchCartRule()
            }

            // Matomo - Cart Update
            // @TODO: to be implemented
            // this.app.$matomo.trackEcommerceCartUpdate(convertCartItemsToMatomoItems(state.items))

            return false
          }
        })
        .catch((err: any) => {
          // @TODO: Add error handling
          // An unhandled error occured
          console.error('validateCart-call failed:', err)
          return false
        })
    },
    removeItemFromCart(payload: CartItem) {
      const index = this.items.indexOf(payload)
      this.items.splice(index, 1)
      this.itemsEdited = true

      // Matomo - Cart Update
      // @TODO: to be implemented
      // this.app.$matomo.trackEcommerceCartUpdate(convertCartItemsToMatomoItems(state.items))

      // @TODO: to be implemented
      // const { convertCartItemToGtmProduct } = useProduct()
      // this.app.$gtm.push({ ecommerce: null, cart_contents: null })
      // this.app.$gtm.push({
      //   event: 'removeFromCart',
      //   ecommerce: {
      //     remove: {
      //       products: [convertCartItemToGtmProduct(payload)]
      //     }
      //   },
      //   cart_contents: state.items.map(item => convertCartItemToGtmProduct(item))
      // })
    },
    updateItemAmount(payload: { item: CartItem, amount: number, referrer: string }) {
      // helper for multitab state, prevent infinity loop
      this.itemsEdited = true
      // const userIsLoggedIn = rootGetters[UserGetters.loggedIn]
      // @TODO: to be implemented
      let oldAmount = this.getCartItemBySku(payload.item.sku /* , payload.item.customization */)?.amount
      // Set old amount as 0 if it was negative
      oldAmount = !oldAmount || oldAmount < 0 ? 0 : oldAmount
      let newAmount = payload.amount

      // If the item is discontinued (productLine === 2), new amount cannot exceed item's stock.qty
      if (payload.item.productLine === 2 && newAmount > payload.item.stock.qty) {
        newAmount = payload.item.stock.qty
      }

      const difference = Math.abs(newAmount - oldAmount)

      // Only update item if newAmount !== oldAmount
      if (difference > 0) {
        // const gtmEvent = oldAmount < newAmount ? 'addToCart' : 'removeFromCart'

        this.loading = true
        // @TODO: does this work?
        // Should we update state?
        payload.item.amount = payload.amount
        // if user is logged in, add item to cart in firestore
        /*
        if (userIsLoggedIn) {
          dispatch('updateItemAmountAuthCart', payload)
        }
        */
        this.loading = false

        // Matomo - Cart Update
        // @TODO: to be implemented
        // this.app.$matomo.trackEcommerceCartUpdate(convertCartItemsToMatomoItems(state.items))

        // Push GTM dataLayer
        // @TODO: to be implemented
        // const { convertCartItemToGtmProduct } = useProduct()
        // const itemCopy = { ...payload.item }
        // delete itemCopy.amount
        // itemCopy.quantity = difference
        // const eventItem = convertCartItemToGtmProduct(itemCopy)
        // let eventObject = null
        // if (gtmEvent === 'addToCart') {
        //   eventObject = {
        //     event: 'addToCart',
        //     addToCartLocation: payload.referrer,
        //     ecommerce: {
        //       currencyCode: 'EUR',
        //       add: { products: [eventItem] }
        //     },
        //     cart_contents: state.items.map(item => convertCartItemToGtmProduct(item))
        //   }
        // }
        // else {
        //   eventObject = {
        //     event: 'removeFromCart',
        //     ecommerce: {
        //       currencyCode: 'EUR',
        //       remove: { products: [eventItem] }
        //     },
        //     cart_contents: state.items.map(item => convertCartItemToGtmProduct(item))
        //   }
        // }
        // this.app.$gtm.push({ ecommerce: null, cart_contents: null })
        // this.app.$gtm.push(eventObject)
      }
    },
    updateItemPrice(payload: { item: CartItem, price: CartItemPrice }) {
      // @TODO: Is this OK?
      payload.item.price = payload.price
    },
    updateItemStock(payload: { item: CartItem, stock: StockObject }) {
      // @TODO: Is this OK?
      payload.item.stock = payload.stock
    },
    // @TODO: Customization to be implemented?
    async addToCartBySku(sku: string, options: { amount?: number, referrer?: string, notifyUser?: boolean, pushGtm?: boolean } = {}): Promise<boolean> {
      // Options
      const _options = { amount: 1, notifyUser: true, referrer: undefined, pushGtm: true, ...options }
      const { result: productResult, search: searchProducts } = useAlgoliaSearch()
      const filters = `sku:'${sku}'`

      await searchProducts({ query: '', requestOptions: { filters, attributesToHighlight: [], cacheable: false } })

      if (productResult.value?.hits) {
        const hits = productResult.value?.hits as ProductAlgolia[]
        if (hits.length === 1) {
          const algoliaProduct: ProductAlgolia = { ...hits[0] }
          // @TODO: customization logic to be implemented?
          // Recalculate custom product prices
          // if (payload.customization) {
          //   algoliaProduct.customization = recalculateCustomizationPrice(algoliaProduct.finalPrice.inclTax, algoliaProduct.price.inclTax, payload.customization)
          // }
          return await this.addToCart(algoliaProduct, _options)
            .then(() => true)
            .catch(() => false)
        }
        else {
          return false
        }
      }
      else {
        return false
      }
    },
    async reloadCartItems() {
      // Clone items
      const items = this.items.map(i => ({ ...i }))
      this.items = []
      this.itemsEdited = true

      for (const item of items) {
        await this.addToCartBySku(item.sku, { amount: item.amount, pushGtm: false, notifyUser: false })
      }
    },
    async useCampaignCode(payload: CampaignCodeQuery): Promise<boolean> {
      const { $auth } = useNuxtApp()
      /**
       * Special case:
       * If campaign code is not user initiated (eg. cart rule code) and requires login,
       * check that user is logged in to apply the campaign code
      */
      if (!payload.isUserInitiated && this.cartRule?.isLoginRequired && useUserStore().loggedIn) {
        // console.log('Skipping applying campaign code as it is not user initiated and requires login, but user is not currently logged in')
        return false
      }

      this.itemsEdited = false

      return await $fetch(useRuntimeConfig().public.FUNCTIONS_API_URL + '/tkapi-campaign-getCampaignCodeDetailsV2', {
        method: 'POST',
        body: { code: payload.code, isUserInitiated: payload.isUserInitiated, cartItems: this.items },
        headers: { Authorization: await $auth.currentUser?.getIdToken() }
      })
        .then((response: any) => {
          if (response.valid) {
            this.campaign = { ...response.code, discountedCartItems: response.discountedCartItems, discountSum: response.discountSum }
            return response
          }
          else {
            // Invalid code: Keep the existing user initiated campaign in store, but set the discounts to zero when code comes back invalidated
            if (this.campaign && !this.campaign.isCartRuleTierCode && !response.error) {
              this.campaign = { ...this.campaign, discountedCartItems: null, discountSum: 0 }
            }
            return false
          }
        })
    },
    async useGiftCardCode(payload: string): Promise<{ valid: boolean, message?: string }> {
      return await $fetch(useRuntimeConfig().public.FUNCTIONS_API_URL + '/veke3000-campaign-getGiftCardCodeDetailsV2?code=' + payload)
        .then((response: any) => {
          if (response.valid && response.openAmount > 0) {
            this.giftCard = response
            return { valid: true }
          }
          else if (response.valid && response.openAmount === 0) {
            return { valid: false, message: 'Lahjakortti on jo käytetty.' }
          }
          else {
            return { valid: false, message: `Lahjakortti '${payload.toUpperCase()}' ei ole käytössä.` }
          }
        })
    },
    async recalculateCartRule(resetItems: boolean = false) {
      console.log('Recalculating cart rule...', resetItems)
      // update cart items if required
      if (resetItems) {
        for (const item of this.items) {
          updateItemIsCartRuleItem(this, { sku: item.sku, isCartRuleItem: await isCartRuleItem(this, item.sku) })
        }
      }

      // Only update cart rule based campaign code if no user initiated campaign code is active
      if (!this.campaign || this.campaign?.isCartRuleTierCode) {
        // By default clear the code
        this.campaign = null
        // Get current cart rule tier
        const currentCartRuleTier = this.cartRule ? this.currentCartRuleTierDiscount(this.cartRule.type) : null
        if (currentCartRuleTier) {
          this.useCampaignCode({ code: currentCartRuleTier.campaignCode, isUserInitiated: false })
        }
        // else {
        //   commit('SET_CAMPAIGN', null)
        // }
      }
    },
    clearCart() {
      this.items = []
      this.itemsEdited = true
      // @TODO: Matomo event
      // @TODO: GTM event
    },
    async fetchCartRule() {
      const { $auth } = useNuxtApp()
      return await $fetch(useRuntimeConfig().public.FUNCTIONS_API_URL + '/tkapi-campaign-getCartRuleDetailsV2', { headers: { Authorization: await $auth.currentUser?.getIdToken() } })
        .then((response: any) => {
          const currentCartRule = this.cartRule
          if (!response.error) {
            this.cartRule = response.rule
            // Recalculate cart items' isCartRuleItem-value if cart rule changed
            this.recalculateCartRule(response.rule?.productSearchFilters !== currentCartRule?.productSearchFilters)
          }
          else {
            this.cartRule = null
            this.recalculateCartRule(true)
          }
        }).catch((e: any) => {
          console.error('Cart rule fetch failed miserably...', e)
        })
    }
  },
  persist: {
    key: 'cart-store',
    storage: piniaPluginPersistedstate.localStorage()
  }
})
