<template>
  <component
    :is="comp.is"
    v-bind="comp.attrs"
    class="text-default hover:no-underline cursor-pointer h-full flex justify-center flex-col rounded hover:bg-grey-50 px-3 py-2 min-h-[50px]"
  >
    <span v-if="item.strap" class="text-muted flex text-xs truncate">
      {{ item.strap }}
    </span>
    <span class="inline-flex items-center gap-x-1.5 font-medium hyphens-auto text-sm">
      <slot>{{ item.text }}<ArrowTopRightOnSquareIcon v-if="item.url" class="size-4 mb-0.5" /></slot>
    </span>
  </component>
</template>

<script setup lang="ts">
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'

interface SearchItemLink {
  text: string
  strap?: string
  path?: string
  url?: string
}

const props = defineProps<{ item: SearchItemLink }>()

const comp = computed(() => {
  return props.item.url
    ? { is: 'a', attrs: { href: props.item.url, target: '_blank' } }
    : { is: resolveComponent('nuxt-link'), attrs: { to: props.item.path } }
})
</script>
