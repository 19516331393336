// composables/useProduct.ts
// import { computed } from 'vue'
import { useCategoryStore } from '~/stores/category'
import type { ProductAlgolia, AttributeValueObject, GtmProduct, ProductCategory, ProductImageVariants, ProductTileAlgolia, ProductTileMiniAlgolia, GtmProductCartItem } from '~/types/product'
import type { Breadcrumb } from '~/types/breadcrumb'
import type { CartItem } from '~/types/item'
import type { MatomoEcommView, MatomoItem } from '~/modules/matomo/types'
import { calculateDiscountPercent } from '~/helpers/price'
import { getMainImage, getVariationImageMasked, getVariationImage } from '~/helpers/product'

export function useProduct() {
  const categoryStore = useCategoryStore()

  // Existing functions
  const convertCartItemsToMatomoItems = (items: CartItem[]): MatomoItem[] => {
    return items.map((item: CartItem) => {
      return {
        sku: item.sku,
        name: item.variationName,
        categories: item.categories,
        price: item.price.finalPrice.inclTax,
        amount: item.amount
      }
    })
  }

  const convertProductToGtmProduct = (product: ProductAlgolia): GtmProduct | null => {
    if (!product) return null
    return {
      item_name: product.name.default, // OR product.name.variant
      item_id: product.id.toString(), // parent SKU
      item_sku: product.sku,
      item_brand: product.marketing?.productManufacturer?.value?.[0] || null,
      price: product.finalPrice.inclTax, // final price, after discounts
      currency: 'EUR',
      item_category: getCategoryPath(product.categories).map(cat => cat.value).join(' > '),
      availability: product.stock.stockStatus.replace('outOfStock', 'out_of_stock').replace('inStock', 'in_stock'), // todo make me real, back order missing?
      ...(product.selectableVariationAttributes.length > 0
        ? { variant: product.selectableVariationAttributes.reduce(
            (acc, sv, index) => acc + (index > 0 ? ', ' : '') + sv.value,
            ''
          )
          }
        : {})
    } as GtmProduct
  }

  const convertAddToCartToGtmProduct = (product: ProductAlgolia): GtmProductCartItem | null => {
    if (!product) return null
    return {
      item_id: product.id.toString(),
      item_name: product.name.variation,
      price: product.finalPrice.inclTax,
      quantity: 1,
      currency: 'EUR'
    }
  }

  const convertCartItemsToGtmProduct = (carItems: CartItem[]): GtmProductCartItem[] | null => {
    if (!carItems) return null
    return carItems.map((cartItem) => {
      return {
        item_id: cartItem.id,
        item_name: cartItem.variationName,
        price: cartItem.price.finalPrice.inclTax,
        quantity: cartItem.amount,
        currency: 'EUR'
      }
    })
  }

  const getBrandList = (productManufacturer: AttributeValueObject | undefined): string => {
    if (productManufacturer?.value && typeof productManufacturer?.value === 'object') {
      return (productManufacturer?.value as string[]).join(', ')
    }
    return ''
  }

  const getCategoryPath = (categories: ProductCategory[]): Breadcrumb[] => {
    const result: Breadcrumb[] = []
    let currLvl = 0
    let currParentId: number | null = null

    for (let i = 0; i < categories.length; i++) {
      const cat = categories[i]

      if (cat.level === currLvl + 1) {
        // Root category
        if (cat.level === 1) {
          const categoryItem = categoryStore.getCategoryById(cat.id)
          if (categoryItem && categoryItem.path) {
            currLvl = cat.level
            currParentId = cat.id
            // @TODO: Add Sentry logging
            result.push({ slug: categoryItem.path, value: cat.value, id: cat.id })
            continue
          }
        }

        // Children
        if (cat.parentId === currParentId) {
          const parent = categories.find(c => c.id === currParentId)

          if (parent) {
            const categoryItem = categoryStore.getCategoryById(cat.id)
            if (categoryItem && categoryItem.path) {
              currLvl = cat.level
              currParentId = cat.id
              // @TODO: Add Sentry logging
              result.push({ slug: categoryItem.path, value: cat.value, id: cat.id })
            }
          }
        }
      }
    }

    return result
  }

  const getAvailableAmount = (product: ProductAlgolia, amount: number): number => {
    // Discontinued product max buy amount
    if (product.productLine === 2) {
      return amount > product.stock.qty ? product.stock.qty : amount
    }

    return amount
  }

  const productName = (product: ProductTileAlgolia) => {
    return product.name?.default || product.name?.variation
  }

  const lowestVariantFinalPrice = (product: ProductTileMiniAlgolia) => {
    return product?.variationPrices ? product?.variationPrices.lowest.finalPrice : product?.finalPrice
  }

  const lowestVariantPrice = (product: ProductTileMiniAlgolia) => {
    return product?.variationPrices ? product?.variationPrices.lowest.price : product?.price
  }

  const hasVariantPriceRange = (product: ProductTileMiniAlgolia) => {
    return !!(product?.variationPrices && product.variationPrices.lowest.finalPrice.inclTax !== product.variationPrices.highest.finalPrice.inclTax)
  }

  const lowestVariantHasDiscount = (product: ProductTileMiniAlgolia) => {
    return lowestVariantFinalPrice(product).inclTax < lowestVariantPrice(product)?.inclTax
  }

  const lowestVariantDiscountPercent = (product: ProductTileMiniAlgolia) => {
    return calculateDiscountPercent(lowestVariantPrice(product).inclTax, lowestVariantFinalPrice(product).inclTax)
  }

  const discountValidUntilText = (product: ProductTileAlgolia) => {
    if (!product.finalPrice.endAt) return ''
    const weekdays = ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la']
    const validUntiDate = new Date(product.finalPrice.endAt)
    const dateStr = `${validUntiDate.getDate()}.${validUntiDate.getMonth() + 1}.`
    return `Tarjous voimassa <strong>vain ${weekdays[validUntiDate.getDay()]} ${dateStr} asti</strong>`
  }

  const schemaStockStatus = (product: ProductTileAlgolia) => {
    switch (product?.stock?.stockStatus) {
      case 'inStock': return 'http://schema.org/InStock'
      case 'outOfStock': return 'http://schema.org/OutOfStock'
      default: return 'http://schema.org/BackOrder'
    }
  }

  const salesPhrases = (product: ProductAlgolia) => {
    const phrases = [
      product?.marketing?.productSalesPhrase1,
      product?.marketing?.productSalesPhrase2,
      product?.marketing?.productSalesPhrase3
    ] as string[]
    return phrases.filter(Boolean)
  }

  const currentOrNextSundayFormattedDate = () => {
    const now = new Date()
    now.setDate(now.getDate() + (0 + (7 - now.getDay())) % 7)
    return now.getDate() + '.' + (now.getMonth() + 1)
  }

  const getDynamicImageUrl = (product: ProductTileAlgolia, showMilieuImage: boolean, variant?: keyof ProductImageVariants): string => {
    return (showMilieuImage ? getMainImage(product, variant) : getVariationImageMasked(product, variant)) || '' // require('~/assets/images/placeholders/kuva-tulossa.svg')
  }

  const getMainImageUrl = (product: ProductTileMiniAlgolia, variant?: keyof ProductImageVariants): string => {
    return getMainImage(product, variant) || '' // require('~/assets/images/placeholders/kuva-tulossa.svg')
  }

  const getMainVariationImageUrl = (product: ProductAlgolia, variant?: keyof ProductImageVariants): string => {
    return getVariationImage(product, variant) || '' // require('~/assets/images/placeholders/kuva-tulossa.svg')
  }

  return {
    convertCartItemsToMatomoItems,
    convertProductToGtmProduct,
    convertAddToCartToGtmProduct,
    convertCartItemsToGtmProduct,
    getBrandList,
    getCategoryPath,
    getAvailableAmount,
    productName,
    lowestVariantFinalPrice,
    lowestVariantPrice,
    hasVariantPriceRange,
    lowestVariantHasDiscount,
    lowestVariantDiscountPercent,
    discountValidUntilText,
    schemaStockStatus,
    salesPhrases,
    currentOrNextSundayFormattedDate,
    getDynamicImageUrl,
    getMainImageUrl,
    getMainVariationImageUrl
  }
}
