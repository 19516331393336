import type { RouteLocationNormalizedGeneric } from 'vue-router'
import breadcrumbNamesJson from '~/config/breadcrumbNames.json'
import { useCategoryStore } from '~/stores/category'
import { useBreadcrumbStore } from '~/stores/breadcrumb'
import type { Breadcrumb } from '~/types/breadcrumb'
import type { CategoryItem } from '~/types/category'

const breadcrumbNames = breadcrumbNamesJson as Record<string, string>

export function useBreadcrumb() {
  const categoryStore = useCategoryStore()
  const breadcrumbStore = useBreadcrumbStore()

  const setBreadcrumbs = (route: RouteLocationNormalizedGeneric) => {
    const routePath = route.path?.replace(/^\/+/, '').replace(/\/+$/, '').split('/') || []
    let categoryPath: Breadcrumb[] | null = []
    const pageType = getPageType(route)

    // Prevent showing breadcrumbs on: HOME, CHECKOUT, THANK-YOU, ETC...
    if (pageType === 'no-breadcrumbs') {
      categoryPath = null
    }
    else if (pageType === 'product') {
      // SKIP PRODUCT page handling: breadcrumb are populated on product page
      return
    }
    else if (pageType === 'uutiset-ja-artikkelit') {
      // HANDLE BLOG
      // if (routePath[1]) return // Blog slugs are handled on uutiset-ja-artikkelit/[...slug]-page
      categoryPath.push({ slug: routePath[0], value: breadcrumbNames[routePath[0]] || routePath[0] })
    }
    else if (pageType === 'wishlist' && routePath[2]) {
      // HANDLE WISHLISTS
      return // Wishlist paths are handled on toivelistat/_id-page
    }
    else if (pageType === 'cms') {
      // HANDLE CMS PAGES
      return // cms paths are handled on cms/[...slug]-page
    }
    else if (pageType === 'category') {
      // CATEGORY-pages
      if (routePath[0] === 'category' && routePath.length === 1) {
        categoryPath.push({ slug: 'category', value: 'Kaikki tuotteet' })
      }
      for (let a = 0; a < routePath.length; a++) {
        if (routePath[a] === 'category') {
          continue
        }
        const currentPath = routePath.slice().splice(0, a + 1).join('/')
        const cat: CategoryItem | undefined = categoryStore.getCategoryByPath(currentPath)
        categoryPath.push({ slug: currentPath, value: cat?.name || '?' })
      }
    }
    else {
      for (let i = 0; i < routePath.length; i++) {
        const currentPath = routePath.slice().splice(0, i + 1).join('/')
        categoryPath.push({ slug: currentPath, value: breadcrumbNames[routePath[i]] || routePath[i] })
      }
    }

    breadcrumbStore.current = categoryPath
  }

  return {
    setBreadcrumbs
  }
}

const getPageType = (route: any) => {
  const page = route.path?.split('/') || []

  switch (page.length > 0 ? page[1] : null) {
    case '':
      return 'no-breadcrumbs'
    case 'kassa':
      return 'no-breadcrumbs'
    case 'kiitos':
      return 'no-breadcrumbs'
    case '_auth':
      return 'no-breadcrumbs'
    case 'category':
      return 'category'
    case 'tuotesarjat':
      return 'category'
    case 'brandit':
      return 'category'
    case 'uutuudet':
      return 'category'
    case 'oma-tili':
      return (page[2] === 'toivelistat' && page.length === 4) ? 'wishlist' : 'default'
    case 'outlet':
      return 'category'
    case 'product':
      return 'product'
    case 'cms':
      return 'cms'
    case 'uutiset-ja-artikkelit':
      return 'uutiset-ja-artikkelit'
    default:
      return 'other'
  }
}
