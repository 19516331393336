import type { ProductAlgolia, ProductImage, ProductImageVariants, ProductTileAlgolia, ProductTileMiniAlgolia } from '~/types/product'

export const formatItemVersion = (itemVersionStr: string): number => {
  return parseInt(itemVersionStr || '') || 1
}

/*
 * IMAGES
 */

export const getUrlFromImage = (image: { url?: string, src?: string, alt?: string, variants?: ProductImageVariants }, variant?: keyof ProductImageVariants): string => (variant && image.variants) ? (image.variants[variant] || image.url || '') : image.url || ''

export const getMainImage = (product: ProductTileMiniAlgolia, variant?: keyof ProductImageVariants) => {
  const image = product?.defaultImage || product?.defaultVariationImage
  return image ? getUrlFromImage(image, variant) : ''
}
export const getVariationImage = (product: ProductAlgolia, variant?: keyof ProductImageVariants) => {
  const image = product?.defaultVariationImage || product?.images[0]
  return image ? getUrlFromImage(image, variant) : ''
}
export const getVariationImageMasked = (product: ProductTileAlgolia, variant?: keyof ProductImageVariants) => {
  const image = product?.images.find((image: ProductImage) => !image.shotAngle?.toLowerCase().includes('miljöö')) || product?.defaultVariationImage
  return image ? getUrlFromImage(image, variant) : ''
}
export const getUpholsteryImages = (product: ProductAlgolia) => product?.images.filter((image: ProductImage) => image.shotAngle?.toLowerCase().includes('verhoilunäyte'))
