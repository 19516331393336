import { useRouter, useRoute } from 'vue-router'
import type { AisRouteState } from '~/types/instantsearch'

export const attributesForProductTile = ['sku', 'itemId', 'slug', 'name', 'yotpo', 'stock', 'finalPrice', 'labels', 'colorVariations', 'defaultImage', 'defaultVariationImage', 'price', 'images', 'erpinfo', 'variationPrices', 'labelTags', 'vatRate']
export const attributesForProductTileMini = ['slug', 'name', 'stock', 'finalPrice', 'defaultImage', 'defaultVariationImage', 'price', 'erpinfo', 'variationPrices']

export const nuxtRouter = () => {
  const router = useRouter()
  const route = useRoute()

  return {
    read(): any {
      return route.query
    },
    write(routeState: AisRouteState) {
      if (this.createURL(routeState) === this.createURL(this.read())) {
        return
      }
      router.push({ query: routeState })
    },
    createURL(routeState: AisRouteState) {
      return router.resolve({ query: routeState }).href
    },
    onUpdate() { },
    dispose() { }
  }
}
