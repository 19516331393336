export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon/favicon-16.png","sizes":"16x16"},{"rel":"icon","type":"image/png","href":"/favicon/favicon-32.png","sizes":"32x32"},{"rel":"icon","type":"image/png","href":"/favicon/favicon-152.png","sizes":"152x152"},{"rel":"icon","type":"image/png","href":"/favicon/favicon-196.png","sizes":"196x196"},{"rel":"preconnect","href":"https://policy.app.cookieinformation.com"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":"anonymous"},{"rel":"stylesheet preload","as":"style","crossorigin":"anonymous","href":"https://fonts.googleapis.com/css2?family=Teko:ital,wght@0,400;0,500;1,400;1,500&family=Figtree:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"}],"style":[],"script":[{"id":"CookieConsent","data-culture":"FI","data-gcm-version":"2.0","src":"https://policy.app.cookieinformation.com/uc.js","async":true},{"id":"CookieConsentListener","src":"/js/cookie-consent-listener.js","defer":true},{"id":"leadoo","src":"https://bot.leadoo.com/bot/dynamic.js?company=JVFGQWlg","async":true},{"id":"cf-turnstile","src":"https://challenges.cloudflare.com/turnstile/v0/api.js","async":true,"defer":true}],"noscript":[],"title":"Laatutuotteet arkeen ja vapaalle","bodyAttrs":{"id":"app"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false