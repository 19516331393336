// composables/useTime.ts

import { defineStore } from 'pinia'
import { format, parse, parseISO } from 'date-fns'
import { fi } from 'date-fns/locale'

export const useTime = defineStore('time', {
  actions: {
    /**
     * @param date - Date object
     * @param pattern - e.g. 'yyyy-MM-dd', 'eee d.M.yyyy HH:mm'
     */
    formatDate(date: Date, pattern = 'd.M.yyyy'): string {
      return format(date, pattern, { locale: fi })
    },

    /**
     * @param dateString
     * @param formatString
     * @param referenceDate
     * @param options
     */
    parseDate(dateString: string, formatString = 'd.M.yyyy', referenceDate: Date | number, options = {}) {
      return parse(dateString, formatString, referenceDate, options)
    },

    /**
     * @param string - Datetime in ISO format
     * @param pattern - e.g. 'yyyy-MM-dd', 'eee d.M.yyyy HH:mm'
     * @param fallback - If empty string, fallback to this
     */
    formatISOString(string: string, pattern = 'd.M.yyyy', fallback = ''): string {
      return string ? format(parseISO(string), pattern, { locale: fi }) : fallback
    }
  }
})
