<template>
  <header class="sticky lg:static -top-12 z-50 w-full inset-x-0 bg-gold-100">
    <div class="container grid gap-4 lg:gap-6 pt-4 pb-2 lg:py-6">
      <div class="flex justify-center lg:justify-between items-start">
        <div class="grid place-items-center lg:place-items-start">
          <NuxtLink :to="localePath('/')" class="block text-center w-fit"><img src="~assets/logo/logo.svg" alt="Tarvikeskus" class="h-6 dark:invert"></NuxtLink>
        </div>
        <nav class="hidden lg:block text-sm">
          <ul class="list-none flex gap-3">
            <li><NuxtLink :to="localePath('/kampanjat')">{{ $t('campaigns') }}</NuxtLink></li>
            <li><NuxtLink :to="localePath('/cms/myymalat')">{{ $t('stores') }}</NuxtLink></li>
            <li><NuxtLink :to="localePath('/cms/asiakaspalvelu')">{{ $t('customerService') }}</NuxtLink></li>
          </ul>
        </nav>
      </div>
      <div class="flex gap-2 lg:gap-6 items-center">
        <BaseButton
          class="size-11 p-0 lg:w-auto lg:px-4 bg-pine-950 hover:bg-pine-900 active:bg-pine-800 text-gold-50"
          :aria-label="$t('menu')"
          :title="$t('menu')"
          look="blank"
          @click="toggleMenu"
        >
          <Bars3Icon class="size-6" aria-hidden="true" />
          <span class="hidden lg:block">{{ $t('menu') }}</span>
        </BaseButton>

        <div class="flex-1 flex gap-2 items-center justify-center">
          <SearchPanel class="flex-1" />
          <BaseButton
            look="blank"
            class="hidden md:inline-grid h-11 py-0 px-2 gap-1 text-pine-950 hover:bg-gold-50"
            to="/varaosahaku"
          >
            <CogIcon class="size-7 text-pine-950" aria-hidden="true" />
            Varaosahaku
          </BaseButton>
        </div>
        <div class="flex gap-px lg:gap-1">
          <BaseButton
            look="blank"
            class="size-11 p-0 hover:bg-gold-50"
            aria-label="Asiakastili"
            title="Asiakastili"
            @click="routeToAccount(localePath('/oma-tili/yhteenveto'))"
          >
            <ClientOnly>
              <template v-if="isLoggedIn && initials">
                <span class="size-6 rounded-full border-[1.5px] border-pine-950 text-pine-950 overflow-hidden inline-flex items-center justify-center font-semibold text-xs">{{ initials }}</span>
              </template>
              <UserCircleIcon v-else class="size-7 text-pine-950" aria-hidden="true" />

              <template #placeholder>
                <UserCircleIcon class="size-7 text-pine-950" aria-hidden="true" />
              </template>
            </ClientOnly>
          </BaseButton>
          <BaseButton
            look="blank"
            class="size-11 gap-0 p-0 relative group hover:bg-gold-50"
            aria-label="Toivelista"
            title="Toivelista"
            @click="routeToAccount(localePath('/oma-tili/toivelistat'))"
          >
            <HeartIcon class="size-7 text-pine-950" aria-hidden="true" />
            <ClientOnly>
              <Transition name="zoom-in">
                <span :key="wishlistItemAmount" :class="{ hidden: wishlistItemAmount < 1 }" class="absolute top-2 right-1.5 ring-2 ring-gold-100 group-hover:ring-gold-50 rounded-full size-2 bg-red-600 text-white" />
              </Transition>
            </ClientOnly>
          </BaseButton>
          <BaseButton
            to="/ostoskori"
            look="blank"
            class="size-11 gap-0 p-0 relative group hover:bg-gold-50"
            aria-label="Ostoskori"
            title="Ostoskori"
          >
            <ShoppingCartIcon class="size-7 text-pine-950" aria-hidden="true" />
            <ClientOnly>
              <TransitionGroup name="bounce">
                <span :key="cartItemAmount" :class="{ hidden: cartItemAmount < 1 }" class="absolute -top-1 -right-1 ring-2 ring-gold-100 group-hover:ring-gold-50 rounded-full size-5 text-xs grid place-items-center bg-red-600 text-white">
                  {{ cartItemAmount }}
                </span>
                <span :key="quoteCartItemAmount" :class="{ hidden: cartItemAmount > 0 || quoteCartItemAmount < 1 }" class="absolute top-2 right-1.5 ring-2 ring-gold-100 group-hover:ring-gold-50 rounded-full size-2 bg-red-600 text-white" />
              </TransitionGroup>
            </ClientOnly>
          </BaseButton>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { HeartIcon, UserCircleIcon, ShoppingCartIcon, Bars3Icon, CogIcon } from '@heroicons/vue/24/outline'
import SearchPanel from '~/components/search/SearchPanel.vue'
import { useUserStore } from '~/stores/user'
import { useCartStore } from '~/stores/cart'
import { useUiStore } from '~/stores/ui'

const localePath = useLocalePath()
const userStore = useUserStore()
const cartStore = useCartStore()
const quoteCartStore = useQuoteCartStore()
const uiStore = useUiStore()
const router = useRouter()
const isLoggedIn = computed(() => userStore.loggedIn)
const initials = computed(() => userStore.initials)
const cartItemAmount = computed(() => cartStore.totalItemAmount)
const quoteCartItemAmount = computed(() => quoteCartStore.totalItemAmount)
const wishlistItemAmount = computed(() => userStore.totalWishlistItemAmount || 0)

const toggleMenu = () => {
  uiStore.toggleMainMenu()
}

const routeToAccount = (accountPath: string) => {
  const path = !isLoggedIn.value ? '/kirjaudu' : accountPath
  router.push(path)
}
</script>
