import { defineStore } from 'pinia'
import type { BreadcrumbState, Breadcrumb } from '~/types/breadcrumb'

export const useBreadcrumbStore = defineStore('breadcrumb', {
  state: (): BreadcrumbState => ({
    current: []
  }),
  actions: {
    setCurrent(crumbs: Breadcrumb[] | null) {
      this.current = crumbs
    },
    addCrumb(crumb: Breadcrumb) {
      if (this.current) {
        this.current.push(crumb)
      }
      else {
        this.current = [crumb]
      }
    }
  }
})
