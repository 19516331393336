import routerOptions0 from "/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.38.0_@types+node@22.5.1_eslint@8.57.0_i_fr5qbuipnalghskr7yycistsku/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/src/nuxt-app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}